// #region Local Imports
import ActionTypes from "../../actionTypes";

const CartActions = {
  Map: (payload: {}) => ({
    payload,
    type: ActionTypes.Cart.SetReducer,
  }),

  Reset: () => ({
    type: ActionTypes.Cart.ResetReducer,
  }),

  setTotalItems: (payload: {}) => ({
    payload,
    type: ActionTypes.Cart.setTotalItems,
  }),

  setCartId: (payload: {}) => ({
    payload,
    type: ActionTypes.Cart.setCartId,
  }),
};

export default CartActions;
