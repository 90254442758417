import { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import CommonService from "../../API/Common";
import { NotificationWithIcon } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import { VALIDATIONS } from "../../Utils/constants";

const PartNumberRefs: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [partnumberData, setPartNumber] = useState<any>([]);

  const getPartNumber = async () => {
    setLoader(true);
    await CommonService.getPartNumber()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          let arr = res?.data.map((item: any) => {
            return {
              id: item?.id,
              title: item?.title,
              description: item?.description,
            };
          });
          setPartNumber(arr);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    async function getPartNumberDetails() {
      await getPartNumber();
    }
    getPartNumberDetails();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: "#",
    },
    {
      title: "Part Numbers reference lists",
      path: "#",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Resources" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl">Part Numbers reference lists</h4>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12">
            <Accordion defaultActiveKey="0" className="custom-accordion">
              {partnumberData.map((dataItem: any) => (
                <Accordion.Item eventKey={dataItem?.id}>
                  <Accordion.Header>{dataItem?.title}</Accordion.Header>
                  <Accordion.Body className="p-0">
                    <div
                      className="custom-part-number-table"
                      dangerouslySetInnerHTML={{
                        __html: dataItem?.description,
                      }}
                    ></div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartNumberRefs;
