/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import landmarkicon from "../../assets/images/landmarkicon.svg";
import mailicon from "../../assets/images/mailicon.svg";
import logo from "../../assets/images/companylogo.png";
import cart from "../../assets/images/cartcircle.svg";
import phoneIcon from "../../assets/images/phone-call.svg";
import leftArrow from "../../assets/images/left-arrow.svg";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../routes";
import { convertToUrlFormat, isUserLoggedIn } from "../../Utils/helper";
import { useSelector } from "react-redux";
import Login from "../../Components/Login";
import Register from "../../Components/Registration";
import ForgotPassword from "../../Components/ForgotPassword";
import ResetPassword from "../../Components/ResetPassword";

interface Props {
  products: any;
  fromPage?: string;
}

const Header: FC<Props> = ({ products, fromPage }: Props) => {
  const [showModal, setShowModal] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [registerData, setRegisterData] = useState<any>(null);
  const navigate = useNavigate();
  const cartState = useSelector((state: any) => state.cart);
  const { totalItems } = cartState;
  const queryParams = new URLSearchParams(useLocation().search);
  const isReset = queryParams.get("isReset");

  const navbarRef = useRef<HTMLElement | null>(null);

  const closeNavbar = (event: MouseEvent) => {
    if (navbarRef.current && !(navbarRef.current as HTMLElement).contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const onNavLinkClick = () => {
    setIsOpen(!isOpen);
  }

  useEffect(() => {
    document.addEventListener('click', closeNavbar);

    return () => {
      document.removeEventListener('click', closeNavbar);
    };
  }, []);


  const handleModal = (value: string) => {
    setShowModal(value);
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const handleRegisterData = (data: any) => {
    setRegisterData(data);
  };

  useEffect(() => {
    if (isReset) {
      handleModal("resetPassword");
    }
  }, []);

  useEffect(() => {
    if (fromPage === "cart") {
      handleModal("login");
    }
  }, [fromPage]);

  const googleTranslateElementInit = () => {
    return new window.google.translate.TranslateElement(
      {
        pageLanguage: process.env.REACT_APP_PAGE_LANGUAGE,
        includedLanguages: process.env.REACT_APP_INCLUDED_LANGUAGE,
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
        multilanguagePage: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    const id = "google-translate-script";

    const addScript = () => {
      const q = document.getElementById(id);
      if (!q) {
        let ele = document.createElement("script");
        ele.setAttribute(
          "src",
          "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
        );
        ele.setAttribute("id", id);
        document.body.appendChild(ele);
        window.googleTranslateElementInit = googleTranslateElementInit;
      }
    };

    const removeScript = () => {
      const q = document.getElementById(id);
      if (q) q.remove();
      const w = document.getElementById("google_translate_element");
      if (w) w.innerHTML = "";
    };

    addScript();

    return () => {
      removeScript();
    };
  }, []);

  return (
    <>
      <div className="top-info-navbar">
        <div className="container d-flex ">
          <ul className="list-unstyled mb-0 me-auto d-flex align-items-center">
            <li className="d-flex align-items-center me-3">
              <img src={landmarkicon} alt="" className="me-2" />
              <span className="c-nav-link">
                16 Walnut Street, Middletown, Maryland
              </span>
            </li>
            <li className="d-flex align-items-center">
              <a href="mailto:info@skyazul.com" className="c-nav-link">
                <img src={mailicon} alt="" className="me-2" />
                <span className="c-nav-link">info@skyazul.com</span>
              </a>
            </li>
          </ul>
          <ul className="list-unstyled mb-0 d-flex align-items-center">
            <li className="d-flex align-items-center">
              <div
                className="custom-dropdown select-language-dropdown"
                id="google_translate_element"
              ></div>
            </li>
            <li className="v-divider mx-3"></li>
            <li className="d-flex align-items-center">
              {!isUserLoggedIn() ? (
                <>
                  <Link
                    to="#"
                    className="c-nav-link"
                    onClick={() => handleModal("login")}
                  >
                    Login
                  </Link>
                  <span className="mx-2 c-nav-link">or</span>
                  <Link
                    to="#"
                    className="c-nav-link"
                    onClick={() => handleModal("register")}
                  >
                    Sign up
                  </Link>
                </>
              ) : (
                <Link to={ROUTES.MYACCOUNT} className="c-nav-link">
                  My Account
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
      <div className="top-navbar">
        <div className="container d-flex flex-wrap align-items-center">
        <div className="image-block">
          <img src={logo} alt="" onClick={() => navigate(ROUTES.HOME)} className="logo img-cursor"/>
          <div className="d-flex align-items-center custom-media-block">
              <Link to={ROUTES.MYCART}>
                <img src={cart} alt="" className="me-2 cart-img" />
              </Link>
              <div>
                <Link to={ROUTES.MYCART}>
                  <h4 className="block-title mb-0 cart-title">Add to Cart</h4>
                </Link>
                {totalItems > 0 && (
                  <p className="cmn-txt cmn-txt-sm mb-0 notranslate">
                    (Item:{totalItems})
                  </p>
                )}
              </div>
            </div>
        </div>
          <ul className="list-unstyled ms-auto mb-0 d-flex pe-3">
            <li className="d-flex align-items-center me-152">
              <img src={phoneIcon} alt="" className="me-2" />
              <div className="display" >
                <p className="cmn-txt mb-0">Phone Services</p>
                <h4 className="block-title block-title-md mb-0">
                  (301) 371-6126
                </h4>
                <h4 className="block-title block-title-md m-0">
                  (281) 201-3690
                </h4>
              </div>
              <div className="display1">
                <p className="cmn-txt mb-0">Phone Services</p>
                <div className="d-flex align-items-center">
                <h4 className="block-title block-title-md mb-0">
                  (301) 371-6126
                </h4>
                <h4 className="block-title block-title-md mb-0">
                  (281) 201-3690
                </h4>
                </div>
              </div>
            </li>
            <li className="d-flex align-items-center custom-media">
              <Link to={ROUTES.MYCART}>
                <img src={cart} alt="" className="me-2" />
              </Link>
              <div>
                <Link to={ROUTES.MYCART}>
                  <h4 className="block-title mb-0">Add to Cart</h4>
                </Link>
                {totalItems > 0 && (
                  <p className="cmn-txt cmn-txt-sm mb-0 notranslate">
                    (Item:{totalItems})
                  </p>
                )}
              </div>
            </li>
          </ul>
          <nav className="navbar navbar-expand-lg navbar-light w-100" ref={navbarRef}>
            <div className="container-fluid px-0 h-100">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleNavbar}
                data-toggle="collapse"
                data-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded={isOpen ? 'true' : 'false'}
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse  ${isOpen ? 'show' : ''}`}
                id="navbarTogglerDemo01"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 h-100">
                  <li className="nav-item h-100" onClick={onNavLinkClick}>
                    <Link
                      to={ROUTES.HOME}
                      className="c-nav-link h-100 d-flex align-items-center"
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item h-100">
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        Product
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {products?.map((dataItem: any) => (
                          <Dropdown.Item key={dataItem.id} onClick={onNavLinkClick}>
                            <Link
                             to={`/readonly-categories/${dataItem.id}/${convertToUrlFormat(dataItem.name)}`}
                              state={{ category: dataItem }}
                            >
                              {dataItem?.name}
                            </Link>
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="nav-item">
                    <Dropdown className="custom-dropdown">
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        About Us
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="w-auto">
                        <Dropdown.Item onClick={onNavLinkClick}>
                          <Link to={ROUTES.NEWS}>News</Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="nav-item">
                    <Dropdown className="custom-dropdown resources-dropdown">
                      <Dropdown.Toggle variant="link" id="dropdown-basic">
                        Resources
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={onNavLinkClick}>
                          <Link to={ROUTES.SERVICE_MANUAL}>
                            Service and Operator’s Manuals Resources
                          </Link>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <Link to={ROUTES.FAQ}>FAQs</Link>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </li>
                  <li className="nav-item h-100" onClick={onNavLinkClick}>
                    <Link
                      to={ROUTES.PRODUCT_CATEGORY}
                      className="c-nav-link d-flex align-items-center h-100"
                    >
                      On-Line Store
                    </Link>
                  </li>
                  <li className="nav-item h-100" onClick={onNavLinkClick}>
                    <Link
                      to={ROUTES.TUTORIALS}
                      className="c-nav-link d-flex align-items-center h-100"
                    >
                      Tutorials
                    </Link>
                  </li>
                  <li className="nav-item h-100" onClick={onNavLinkClick}>
                    <Link
                      to={ROUTES.CONTACT}
                      className="c-nav-link d-flex align-items-center h-100"
                    >
                      Contact Us
                    </Link>
                    </li>
                  </ul>
                  <ul className="p-0 m-0 rght-btn">
                    <li onClick={onNavLinkClick}>
                      <Link
                        className="btn--primary btn rounded-0 prime-pad"
                        to={ROUTES.REQUEST_QUOTE}
                      >
                        Get A Quote <img src={leftArrow} alt="" className="ml-2" />
                      </Link>
                    </li>
                  </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>

      {showModal === "login" && (
        <Login
          show={showModal === "login"}
          handleModal={handleModal}
          handleRegisterData={handleRegisterData}
        />
      )}
      {showModal === "register" && (
        <Register
          show={showModal === "register"}
          handleModal={handleModal}
          handleSocialData={registerData}
        />
      )}
      {showModal === "forgotPassword" && (
        <ForgotPassword
          show={showModal === "forgotPassword"}
          handleModal={handleModal}
        />
      )}
      {showModal === "resetPassword" && (
        <ResetPassword
          show={showModal === "resetPassword"}
          handleModal={handleModal}
        />
      )}
    </>
  );
};

export default React.memo(Header);
