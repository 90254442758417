import { FC, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ErrorMessage, Field, Formik } from "formik";
import { Form } from "antd";
import AuthService from "../../API/Auth";
import { forgotPasswordValidations } from "../../Utils/validations";
import { NotificationWithIcon } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import { VALIDATIONS } from "../../Utils/constants";
import successIcon from "../../assets/images/succes.svg";

interface Props {
  show: boolean;
  handleModal: any;
}

const ForgotPassword: FC<Props> = ({ show, handleModal }: Props) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [form] = Form.useForm();

  const handleClose = () => {
    handleModal("");
  };

  const onForgotPassword = async (
    values: { email: string },
    resetForm: any
  ) => {
    setLoader(true);
    let data = {
      email: values.email,
    };
    await AuthService.forgotPassword(data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          resetForm();
          if (res?.error) {
            NotificationWithIcon("error", res?.message);
            return;
          }
          setSuccessMsg(res?.message);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal custom-modal-sm"
        centered
      >
        <Modal.Header
          closeButton
          className="flex-column align-items-start float-close-btn border-0"
        >
          <Modal.Title>Forgot Password</Modal.Title>
          <p className="cmn-txt cmn-txt-md mt-1 mb-0">
            Lost your password? Please enter your email address. You will
            receive a link to create a new password via email.
          </p>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={(values, { resetForm }) => {
              onForgotPassword(values, resetForm);
            }}
            validationSchema={forgotPasswordValidations}
          >
            {({ handleSubmit }: any) => (
              <Form
                layout="vertical"
                form={form}
                onSubmitCapture={handleSubmit}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Form.Item label="Email address*" className="custom-field mb-3">
                  <Field
                    id="email"
                    name="email"
                    placeholder="Enter email address"
                    className="form-control custom-form-control"
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    className="error-msg"
                  />
                </Form.Item>
                <Form.Item>
                  <button type="submit" className="w-100 btn--primary btn">
                    Submit
                  </button>
                </Form.Item>
              </Form>
            )}
          </Formik>
          {successMsg && (
            <>
              <p className="success--msg">
                <img src={successIcon} alt="" />
                {successMsg}
              </p>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ForgotPassword;
