import { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Header from "../../Common/Header";
import { isUserLoggedIn, NotificationWithIcon } from "../../Utils/helper";
import IdleTimeOutHandler from "../../Utils/IdleTimeOutHandler";
import Cookie from "js-cookie";
import { USER_IDLE_TIME_IN_MILLISECONDS } from "../../Utils/constants";
import ProductService from "../../API/Product";

interface Props {
  children: JSX.Element;
  fromPage?: string;
}

const Layout = ({ children, fromPage }: Props) => {
  const [, setIsActive] = useState<boolean>(true);
  const [productList, setProductList] = useState<any>([]);

  const isUserAuthenticated = isUserLoggedIn();

  const getProductList = async () => {
    await ProductService.getReadOnlyCategory()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setProductList(res?.data);
        }
      })
      .catch((err: any) => {
        NotificationWithIcon("error", err?.data?.error?.message);
      });
  };

  useEffect(() => {
    Cookie.set("idleTime", USER_IDLE_TIME_IN_MILLISECONDS);
    async function getProduct() {
      await getProductList();
    }
    getProduct();
  }, []);

  return (
    <>
      {isUserAuthenticated && (
        <IdleTimeOutHandler
          onActive={() => {
            setIsActive(true);
          }}
          onIdle={() => {
            setIsActive(false);
          }}
        />
      )}
      <Header products={productList} fromPage={fromPage} />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
