export const CONTACT_LENGTH = 14;
export const AUTH_TOKEN = "accessToken";
export const PAGESIZE = 12;
export const REVIEW_PAGESIZE = 5;
export const RELATED_PRODUCTS_PAGESIZE = 4;
export const CONTACT_REGEX = /^\+?[1-9]\d{8,14}$/;
export const ZIPCODE_REGEX = /^\d{4,10}$/;
export const PASSWORD_REGEX =
  /((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%?=*&]).{8,15})$/; //NOSONAR
export const EMAIL_REGEX = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/; //NOSONAR
export const CVV_LENGTH = 4;
export const CVV_REGEX = /^[\d]{3,4}$/;
export const STRING_REGEX = /\s+/g;
export const NUMERIC_REGEX = /[^\d]/gi;
export const CARD_NUMBER_REGEX = /\d{4,16}/g;
export const STRING_NUMERIC_REGEX = /[^\w\s]/gi;
export const CARD_NUMBERS_LENGTH = [18, 19];
export const STATUS_DELIVERED = "Delivered";
export const STATUS_CANCELLED = "Cancelled";
export const READ_MORE_CHAR_SIZE = 500;
export const FAX_REGEX = /^\+[\d]{1,3}-[\d]{3}-[\d]{7}$/;
export const PRODUCT_REVIEW_DATE_FROMAT = "MMMM D, YYYY";
export const DATE_FROMAT = "YYYY-MM-DD";
export const USER_IDLE_TIME_IN_MILLISECONDS = "1800000";
export const NAME_VALIDATION_REGEX = /^[A-Za-z]+([ A-Za-z]([-']?[A-Za-z]+)*)+$/; //NOSONAR
export const MAX_NAME_LENGTH = 25;
export const MAX_STREET_ADDRESS_LENGTH = 100;
export const GOOGLE_MAP_URL =
  "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3081.1233046553903!2d-77.55386892506735!3d39.443944971612574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x22485ca3767311c6!2zMznCsDI2JzM4LjIiTiA3N8KwMzMnMDQuNyJX!5e0!3m2!1sen!2sin!4v1673430211711!5m2!1sen!2sin";
export const PRICE_DECIMAL_LIMIT = 2;

export const VALIDATIONS = Object.freeze({
  INVALID_CONTACT: "Please enter valid contact number",
  USERNAME_EMAIL_REQUIRED: "Please enter username or email address",
  PASSWORD_REQUIRED: "Please enter password",
  FIRSTNAME_REQUIRED: "Please enter firstname",
  LASTNAME_REQUIRED: "Please enter lastname",
  EMAIL_REQUIRED: "Please enter email",
  INVALID_EMAIL: "Please enter valid email",
  CONTACT_REQUIRED: "Please enter contact number",
  COUNTRY_REQUIRED: "Please select country",
  INVALID_PASSWORD:
    "Password must contain at least one number and one uppercase and one special characters, and at least 8 and at most 15 characters",
  CONFIRM_PASSWORD_REQUIRED: "Please enter confirm password",
  PASSWORD_NOT_MATCH: "Password and Confirm password did not match",
  USERNAME_REQUIRED: "Please enter username",
  OLD_PASSWORD_REQUIRED: "Please enter old password",
  NEW_PASSWORD_REQUIRED: "Please enter new password",
  QUANTITY_LIMIT_ERROR: "You can add upto {totalQuantity}",
  CAPTCHA_REQUIRED: "Please validate captcha",
  PROFILE_UPDATE: "Profile updated successfully",
  STREET_ADDRESS_REQUIRED: "Please enter street address",
  STATE_REQUIRED: "Please select state",
  CITY_REQUIRED: "Please select town / city",
  ZIPCODE_REQUIRED: "Please enter zipcode",
  OLD_NEW_MATCH: "New Password must be different from Old Password",
  COUPON_REQUIRED: "Please enter code",
  CART_ITEM_DELETE: "Product deleted successfully",
  APPLY_CODE_ERROR: "Please add at least one product in cart",
  APPLY_CODE_SUCCESS: "Coupon code is applied",
  NAME_REQUIRED: "Please enter your name",
  SUBJECT_REQUIRED: "Please enter subject",
  MESSAGE_REQUIRED: "Please enter message",
  CONTACT_SUCCESS: "Your request is send successfully",
  REVIEW_REQUIRED: "Please enter review",
  CARD_HOLDERNAME_REQUIRED: "Please enter card holder name",
  CARD_NUMBER_REQURIED: "Please enter card number",
  CVV_REQUIRED: "Please enter CVV",
  CVV_INVALID: "Please enter valid CVV",
  CARD_NUMBER_LENGTH: "Card number length must be 15 OR 16 number",
  CARD_SELECTION_REQUIRED: "Please select card",
  EXPIRY_DATE_REQUIRED: "Please select expiry month",
  BILLING_SHIPPING_REQUIRED:
    "Please add billing and shipping address for checkout",
  ADDRESS_REQUIRED: "Please enter address",
  COMPANY_REQUIRED: "Please enter company",
  INTERESTED_PRODUCTS_REQUIRED: "Select product",
  FAX_INVALID: "Please enter valid fax number",
  FAX_REQUIRED: "Please enter fax",
  COUPON_EXPIRED: "This coupon is expired",
  INVALID_NAME: "Please enter valid name.You can add name upto 25 character",
  INVALID_ZIPCODE: "Please enter valid zipcode",
  SOMETHING_WENT_WRONG: "Something went wrong",
  SIGNIN_EMAIL_ACCESS_ERROR:
    "Email access denied, please check your app settings.",
});

export enum ADDRESS_TYPE {
  BILLING = "billing",
  SHIPPING = "shipping",
}

export enum DISCOUNT_TYPE {
  FIXED = "Fixed cart discount",
  PERCENTAGE = "Percentage discount",
  PER_PRODUCT = "Fixed product discount",
}

export enum SOCIAL_LOGIN_PROVIDER {
  FACEBOOK = "facebook",
  GOOGLE = "google",
  APPLE = "apple",
}

export const PRODUCT_SORTING = [
  {
    label: "Default Sorting",
    value: "title:asc",
  },
  {
    label: "Sort by latest",
    value: "id:desc",
  },
  {
    label: "Sort by price: low to high",
    value: "price:asc",
  },
  {
    label: "Sort by price: high to low",
    value: "price:desc",
  },
];

export const READ_ONLY_PRODUCT_FILTER = [
  {
    label: "Current Products",
    value: "isLegacy=false",
  },
  {
    label: "All Products",
    value: "",
  },
  {
    label: "Legacy Products",
    value: "isLegacy=true",
  },
];

export const DEFAULT_COUNTRY = {
  name: "United States",
  id: 233,
};
