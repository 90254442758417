/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ArticleService from "../../API/Articles";
import Loader from "../../Common/Loader";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import * as Constants from "../../Utils/constants";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";

const ArticleDetails: FC = () => {
  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: ROUTES.ARTICLES,
    },
    {
      title: "Industry Standard and Articles Details",
      path: "#",
    },
  ];

  const [loader, setLoader] = useState<boolean>(false);
  const [articleInfo, setArticleInfo] = useState<any>("");
  const { id } = useParams();

  const getArticleDetails = async () => {
    setLoader(true);
    const queryParams = "all=true&populate[images][fields]=url";
    try {
      const res: any = await ArticleService.getArticleDetails(
        Number(id),
        queryParams
      );
      if (res && res?.status === "Success") {
        let response = res?.data;
        let longDec = response.longDescription;
        let el = document.createElement("div");
        el.innerHTML = longDec;

        el.querySelectorAll("img").forEach((imgEl) => {
          const srcAtt = imgEl.getAttribute("src");
          if (srcAtt) {
            const srcOriginal = geturl(srcAtt);
            imgEl.src = srcOriginal;
          }
        });
        longDec = el.innerHTML;
        response = { ...response, longDescription: longDec };
        setArticleInfo(response);
        setLoader(false);
      }
    } catch (err: any) {
      setLoader(false);
      NotificationWithIcon(
        "error",
        err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
      );
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getArticleInfo() {
      if (id) {
        await getArticleDetails();
      }
    }
    getArticleInfo();
  }, [id]);

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Article Details" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl text--dark">{articleInfo?.title}</h4>
            <div className="hr--divider border-light-grey w-100"></div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 mb-3">
            <p className="cmn-txt cmn-txt-md">{articleInfo?.description}</p>
          </div>
          <div
            className="col-12 mb-3"
            dangerouslySetInnerHTML={{
              __html: articleInfo.longDescription,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ArticleDetails;
