/* eslint-disable no-underscore-dangle */
/* eslint-disable no-alert */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import moment from "moment";
import Cookie from "js-cookie";
import { handleLogout } from "./helper";
import { AUTH_TOKEN } from "./constants";
import { useNavigate } from "react-router-dom";
import ROUTES from "../routes";

interface Props {
  onActive: any;
  onIdle: any;
}

const IdleTimeOutHandler: FC<Props> = ({ onActive, onIdle }: Props) => {
  let timer: any;
  const navigate = useNavigate();
  const token = Cookie.get(AUTH_TOKEN);
  const events = [
    "click",
    "scroll",
    "load",
    "keydown",
    "mousemove",
    "mousedown",
  ];
  const timeOutInterval = Number(Cookie.get("idleTime"));

  const startTimer = () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const lastInteractionTime = localStorage.getItem("lastInteractionTime");
      const diff: any = moment.duration(
        moment().diff(moment(lastInteractionTime))
      );
      const timeoutInterval = timeOutInterval;

      if (diff._milliseconds <= timeoutInterval) {
        startTimer();
        onActive();
      } else {
        onIdle();
        clearTimeout(timer);
        if (token) {
          Cookie.remove("idleTime");
          localStorage.removeItem("lastInteractionTime");
          handleLogout();
          navigate(ROUTES.HOME);
        }
      }
    }, timeOutInterval);
  };

  const eventHandler = () => {
    localStorage.setItem("lastInteractionTime", moment().toString());
    if (timer) {
      onActive();
      startTimer();
    }
  };

  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
    startTimer();
  };

  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };

  useEffect(() => {
    localStorage.setItem("lastInteractionTime", moment().toString());
    addEvents();
    return () => {
      removeEvents();
    };
  }, []);

  return <div />;
};

export default IdleTimeOutHandler;
