const ROUTES = {
  HOME: "/",
  CHANGEPASSWORD: "/change-password",
  PRODUCTS: "/product-category/:id/:name",
  PRODUCT_CATEGORY: "/product-category",
  PRODUCT_DETAILS: "/product/:id/:name",
  MYACCOUNT: "/myAccount",
  MYCART: "/cart",
  MYORDERS: "/myOrders",
  MANAGEADDRESS: "/manageAddress",
  PAYMENTMETHOD: "/payment",
  FAQ: "/faq",
  NEWS: "/news",
  CONTACT: "/contact-us",
  ARTICLES: "/articles",
  ARTICLESDETAILS: "/articleDetails/:id",
  TUTORIALS: "/tutorials",
  PARTNUMBER: "/partNumber",
  SERVICE_MANUAL: "/manuals",
  MANUAL_DETAILS: "/manuals/:title",
  READONLYCATEGORY: "/readonly-categories/:id/:name",
  READONLYPRODUCT:"/readonly-product/:id",
  MAINPRODUCTDETAILS: "/readonly-category/:id/:name",
  REQUEST_QUOTE: "/request-quote",
  PRIVACY_POLICY: "/privacy-policy",
  RESOURCES: "/resource",
  ABOUT_US: "/about-us"
};

export default ROUTES;
