/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { Form } from "antd";
import AuthService from "../../API/Auth";
import { resetPasswordValidations } from "../../Utils/validations";
import { NotificationWithIcon, passwordForm } from "../../Utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Common/Loader";
import ROUTES from "../../routes";
import { VALIDATIONS } from "../../Utils/constants";

interface Props {
  show: boolean;
  handleModal: any;
}

const ResetPassword: FC<Props> = ({ show, handleModal }: Props) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const search = useLocation().search;
  const code = new URLSearchParams(search).get("code");

  const verifyCode = async () => {
    setLoader(true);
    await AuthService.resetLinkVerification({ code })
      .then(() => {
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        handleClose();
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
        setTimeout(() => {
          navigate(ROUTES.HOME);
        }, 500);
      });
  };

  useEffect(() => {
    if (code) {
      verifyCode();
    }
  }, [code]);

  const handleClose = () => {
    handleModal("");
  };

  const onResetPassword = async (values: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    setLoader(true);
    let data = {
      password: values.newPassword,
      passwordConfirmation: values.confirmPassword,
      code,
    };
    await AuthService.resetPassword(data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", res?.message);
          handleClose();
          setTimeout(() => {
            navigate(ROUTES.HOME);
          }, 1000);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal custom-modal-sm"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Set New Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={(values) => {
              onResetPassword(values);
            }}
            validationSchema={resetPasswordValidations}
          >
            {({ handleSubmit }: any) => (
              <Form
                layout="vertical"
                form={form}
                onSubmitCapture={handleSubmit}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
              >
                {passwordForm()}
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ResetPassword;
