/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import CommonService from "../../API/Common";
import PdfListing from "../../Common/PdfListing";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import ReactPlayer from "react-player";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { PAGESIZE, VALIDATIONS } from "../../Utils/constants";

const Tutorials: FC = () => {
  const [pdfData, setPdfData] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [video, setVideo] = useState([]);
  const [videoPage, setVideoPage] = useState(1);
  const [pdfPage, setPdfPage] = useState(1);
  const [videoTotal, setVideoTotal] = useState(0);
  const [pdfTotal, setPdfTotal] = useState(0);
  const [showLoadMoreVideo, setShowLoadMoreVideo] = useState<boolean>(false);
  const [showMoreSelectedVideo, setShowMoreSelectedVideo] =
    useState<boolean>(false);
  const [showLoadMorePdf, setShowLoadMorePdf] = useState<boolean>(false);
  const [showMoreSelectedPdf, setShowMoreSelectedPdf] =
    useState<boolean>(false);

  const errorBlock = (err: any) => {
    setLoader(false);
    NotificationWithIcon(
      "error",
      err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
    );
  };

  const getVideoTutorials = async (page: number) => {
    setLoader(true);
    await CommonService.getTutorials(
      `start=${page}&limit=${PAGESIZE}&tutorial=Video`
    )
      .then((res: any) => {
        if (res && res?.status === "Success") {
          const arr = res?.data;
          const videoArr = arr?.map((item: any) => {
            return {
              id: item?.id,
              videoLink: item?.Video?.videoLink,
              videoTitle: item?.Video?.title,
              videoDescription: item?.Video?.description,
            };
          });
          let videoArray: any = [];
          if (showMoreSelectedVideo) {
            videoArray = [...video, ...videoArr];
          } else {
            setVideo([]);
            videoArray = [...videoArr];
          }
          setVideo(videoArray);
          setShowMoreSelectedVideo(false);
          setVideoTotal(res?.meta?.total);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        errorBlock(err);
      });
  };

  const getPdfTutorials = async (page: number) => {
    setLoader(true);
    await CommonService.getTutorials(
      `start=${page}&limit=${PAGESIZE}&tutorial=PDF`
    )
      .then((res: any) => {
        if (res && res?.status === "Success") {
          const arr = res?.data;
          const pdfArr = arr?.map((item: any) => {
            return {
              id: item?.id,
              pdfTitle: item?.PDF?.Title,
              pdf: geturl(item?.PDF?.PDF?.url),
            };
          });
          let pdfArray: any = [];
          if (showMoreSelectedPdf) {
            pdfArray = [...pdfData, ...pdfArr];
          } else {
            setPdfData([]);
            pdfArray = [...pdfArr];
          }
          setPdfData(pdfArray);
          setShowMoreSelectedPdf(false);
          setPdfTotal(res?.meta?.total);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        errorBlock(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getTutorialsDetails() {
      await getVideoTutorials(videoPage);
      await getPdfTutorials(pdfPage);
    }
    getTutorialsDetails();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Tutorials",
      path: "#",
    },
  ];

  const onViewMore = (type: string) => {
    if (type === "video") {
      setVideoPage((p) => p + 1);
      setShowMoreSelectedVideo(true);
    } else {
      setPdfPage((p) => p + 1);
      setShowMoreSelectedPdf(true);
    }
  };

  useEffect(() => {
    if (videoPage > 1) {
      getVideoTutorials(videoPage);
    }
  }, [videoPage]);

  useEffect(() => {
    if (pdfPage > 1) {
      getPdfTutorials(pdfPage);
    }
  }, [pdfPage]);

  useEffect(() => {
    if (video?.length < videoTotal) {
      setShowLoadMoreVideo(true);
    }
    if (video?.length === videoTotal) {
      setShowLoadMoreVideo(false);
    }
  }, [video]);

  useEffect(() => {
    if (pdfData?.length < pdfTotal) {
      setShowLoadMorePdf(true);
    }
    if (pdfData?.length === pdfTotal) {
      setShowLoadMorePdf(false);
    }
  }, [pdfData]);

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Tutorials" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            <Tabs
              id="controlled-tab-example"
              className="mb-5 custom-tab tutorial-tab"
              defaultActiveKey="videotutorial"
            >
              <Tab eventKey="videotutorial" title="Video Tutorials">
                <div className="row">
                  <>
                    {video.map((dataItem: any) => (
                      <div className="col-md-6 mb-3" key={dataItem.id}>
                        <div className="card custom-card tutorial-card">
                          <div className="card-header">
                            <ReactPlayer
                              url={dataItem.videoLink}
                              title={dataItem?.videoTitle}
                              controls
                              width="100%"
                              height="100%"
                            />
                          </div>
                          <div className="card-body">
                            <h4 className="block-title block-title-md font-weight-semibold">
                              {dataItem?.videoTitle}
                            </h4>
                            <p className="cmn-txt cmn-txt-md text-ellipsis line-2">
                              {dataItem?.videoDescription}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                    {video.length === 0 && (
                      <div>
                        <h3>No data found</h3>
                      </div>
                    )}
                  </>
                  {showLoadMoreVideo && (
                    <div className="col-12 d-flex justify-content-center">
                      <button
                        className="btn btn-borderd--primary"
                        onClick={() => onViewMore("video")}
                      >
                        View More
                      </button>
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="pdftutorial" title="PDF Tutorials">
                <div className="container pb-5">
                  <div className="row mt-3">
                    <div className="col-12 p-0">
                      <div className="list--box tutorial">
                        <ul className="list-unstyled doc--list">
                          {pdfData.length > 0 && <PdfListing pdfs={pdfData} />}
                        </ul>
                      </div>
                      {pdfData.length === 0 && (
                        <div>
                          <h3>No data found</h3>
                        </div>
                      )}
                    </div>
                    {showLoadMorePdf && (
                      <div className="col-12 d-flex justify-content-center">
                        <button
                          className="btn btn-borderd--primary"
                          onClick={() => onViewMore("pdf")}
                        >
                          View More
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tutorials;
