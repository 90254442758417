/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ErrorMessage, Field, Formik } from "formik";
import { Form, Select } from "antd";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import AuthService from "../../API/Auth";
import {
  registerValidations,
  updateProfileValidations,
} from "../../Utils/validations";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "../../routes";
import * as Constants from "../../Utils/constants";
import {
  filterData,
  getCountries,
  isUserLoggedIn,
  NotificationWithIcon,
  validateContact,
} from "../../Utils/helper";
import AuthActions from "../../Redux/action/auth";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Common/Loader";
import CartActions from "../../Redux/action/cart";

const siteKey: any = process.env.REACT_APP_CAPTCHA_CLIENT_KEY;

interface Props {
  show: boolean;
  handleModal: any;
  handleSocialData?: any;
}

const Register: FC<Props> = ({
  show,
  handleModal,
  handleSocialData,
}: Props) => {
  const [countries, setCountries] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const cartState = useSelector((state: any) => state.cart);
  const { cartId } = cartState;
  const [form] = Form.useForm();
  const recaptchaRef: any = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const fromPage = queryParams.get("fromPage");
  const defaultCountry: any = Constants.DEFAULT_COUNTRY;

  const handleRoute = () => {
    if (fromPage === "cart") {
      return ROUTES.MYCART;
    }
    return ROUTES.HOME;
  };

  const redirectTo = handleRoute();

  const getAllCountry = async () => {
    await getCountries(setCountries);
  };

  useEffect(() => {
    async function getCountry() {
      await getAllCountry();
    }
    getCountry();
  }, []);

  const handleClose = () => {
    handleModal("");
  };
  const onRegister = async (values: any, setFieldValue: any) => {
    setLoader(true);
    let data: any = {
      username: `${values.firstname.trim()} ${values.lastname.trim()}`,
      email: values.email,
      contactNumber: values.contact,
      country: Number(values.country),
    };
    if (handleSocialData) {
      data = {
        ...data,
        platform: handleSocialData?.socialLoginRes?.provider,
        isSocialLogin: true,
      };
    } else {
      data = { ...data, password: values.password };
    }
    if (!isUserLoggedIn() && cartId) {
      data = { ...data, cartId };
      dispatch(CartActions.Reset());
    }
    await AuthService.postRegister(data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          const { AUTH_TOKEN } = Constants;
          Cookie.set(AUTH_TOKEN, res?.data?.jwt);
          if (handleSocialData) {
            dispatch(
              AuthActions.setIsSocialLogin({
                isSocialLogin: true,
              })
            );
          }
          dispatch(
            AuthActions.setUserInfo({
              userInfo: res?.data?.user,
            })
          );
          NotificationWithIcon("success", res?.message);
          handleClose();
          navigate(redirectTo);
          setTimeout(() => {
            navigate(0);
          }, 400);
        }
      })
      .catch((err: any) => {
        if (!handleSocialData) {
          recaptchaRef.current.reset();
          setFieldValue("recaptcha", "");
        }
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal custom-modal-md custom-modal-signup-form"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {handleSocialData ? "Update Profile" : "Register Your Account"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="signup-form">
          <Container fluid>
            <Formik
              initialValues={{
                firstname: handleSocialData?.socialLoginRes?.firstname || "",
                lastname: handleSocialData?.socialLoginRes?.lastname || "",
                email: handleSocialData?.socialLoginRes?.email || "",
                contact: "",
                country: defaultCountry.id,
                password: "",
                confirmPassword: "",
                recaptcha: "",
              }}
              onSubmit={(values, { setFieldValue }) => {
                onRegister(values, setFieldValue);
              }}
              validationSchema={
                handleSocialData
                  ? updateProfileValidations
                  : registerValidations
              }
              validateOnBlur={false}
              enableReinitialize={true}
            >
              {({
                handleSubmit,
                setFieldValue,
                errors,
                setFieldTouched,
                values,
              }) => (
                <Form
                  layout="vertical"
                  form={form}
                  onSubmitCapture={handleSubmit}
                >
                  <Row className="input-field">
                    <Col className="col-lg-6 col-sm-6 col-12">
                      <Form.Item
                        label="First name*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="firstname"
                          name="firstname"
                          placeholder="Enter firstname"
                          className="form-control custom-form-control"
                          value={values.firstname}
                        />
                        <ErrorMessage
                          name="firstname"
                          component="span"
                          className="error-msg"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-12">
                      <Form.Item
                        label="Last name*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="lastname"
                          name="lastname"
                          placeholder="Enter lastname"
                          className="form-control custom-form-control"
                          value={values.lastname}
                        />{" "}
                        <ErrorMessage
                          name="lastname"
                          component="span"
                          className="error-msg"
                        />
                      </Form.Item>
                    </Col>
                  
                    <Col className="col-lg-6 col-sm-6 col-12">
                      {" "}
                      <Form.Item
                        label="Email address*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="email"
                          name="email"
                          placeholder="Enter email address"
                          className="form-control custom-form-control"
                          value={values.email}
                        />{" "}
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="error-msg"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-12">
                      <Form.Item
                        label="Contact number*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="contact"
                          name="contact"
                          validate={validateContact}
                          render={(renderProps: any) => (
                            <input
                              {...renderProps.field}
                              type="text"
                              placeholder="Enter contact number"
                              maxLength={Constants.CONTACT_LENGTH}
                              className="form-control custom-form-control"
                            />
                          )}
                          value={values.contact}
                        />{" "}
                        <ErrorMessage
                          name="contact"
                          component="span"
                          className="error-msg"
                        />
                      </Form.Item>
                    </Col>
                  
                    <Col className="col-lg-6 col-sm-6 col-12">
                      <Form.Item label="Country*" className="custom-field mb-3">
                        <Select
                          placeholder="Select country"
                          popupClassName="custom--select"
                          showSearch
                          options={countries?.map((item: any) => ({
                            label: item?.name,
                            value: item?.id,
                          }))}
                          onChange={(e: any) => {
                            setFieldValue("country", e);
                          }}
                          filterOption={(input: any, option: any) =>
                            filterData(input, option)
                          }
                          value={values.country}
                        />
                        <ErrorMessage
                          name="country"
                          component="span"
                          className="error-msg"
                        />
                      </Form.Item>
                    </Col>
                    <Col className="col-lg-6 col-sm-6 col-12">
                      {!handleSocialData && (
                        <Form.Item
                          label="Password*"
                          className="custom-field mb-3"
                        >
                          <Field
                            id="password"
                            name="password"
                            placeholder="Enter password"
                            type="password"
                            className="form-control custom-form-control"
                          />{" "}
                          <ErrorMessage
                            name="password"
                            component="span"
                            className="error-msg"
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </Row>
                  {!handleSocialData && (
                    <>
                      <Row>
                        <Col lg="6">
                          <Form.Item
                            label="Confirm password*"
                            className="custom-field mb-3"
                          >
                            <Field
                              id="confirmPassword"
                              name="confirmPassword"
                              placeholder="Enter confirm password"
                              type="password"
                              className="form-control custom-form-control"
                            />{" "}
                            <ErrorMessage
                              name="confirmPassword"
                              component="span"
                              className="error-msg"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Item>
                            <ReCAPTCHA

                              ref={recaptchaRef}
                              sitekey={siteKey}
                              onChange={(response: any) => {
                                setFieldValue("recaptcha", response);
                                setFieldTouched("recaptcha", true);
                              }}
                              className="sign-up-capta"
                            />
                            <ErrorMessage
                              name="recaptcha"
                              component="span"
                              className="error-msg"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Form.Item className="mt-3 text-center">
                    <Button
                      type="submit"
                      className="w-100 btn--primary btn w-400"
                      onClick={() => {
                        if (
                          Object.keys(errors).length > 0 &&
                          !handleSocialData
                        ) {
                          if (values.recaptcha) {
                            recaptchaRef.current.reset();
                            setFieldValue("recaptcha", "");
                          }
                        }
                      }}
                    >
                      {handleSocialData ? "Save" : "Register"}
                    </Button>
                  </Form.Item>
                  {!handleSocialData && (
                    <p className="text-center b-t-w-l">
                      Back to
                      <Link
                        to="#"
                        className="ms-2 a-r-o-link"
                        onClick={() => handleModal("login")}
                      >
                        Login
                      </Link>
                    </p>
                  )}
                </Form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
