/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import removeicon from "../../assets/images/delete.svg";
import Quantity from "../../Common/Quantity";
import Loader from "../../Common/Loader";
import CartService from "../../API/Cart";
import { useDispatch, useSelector } from "react-redux";
import {
  filterData,
  formatPrice,
  getCountries,
  getErrorMessage,
  isUserLoggedIn,
  NotificationWithIcon,
  UUID,
  validateZipcode,
} from "../../Utils/helper";
import {
  DISCOUNT_TYPE,
  VALIDATIONS,
  DATE_FROMAT,
  DEFAULT_COUNTRY,
  MAX_STREET_ADDRESS_LENGTH,
} from "../../Utils/constants";
import CartActions from "../../Redux/action/cart";
import { Field, Formik } from "formik";
import { couponValidation, shippingValidation } from "../../Utils/validations";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Form, Radio, Select } from "antd";
import CommonService from "../../API/Common";
import ROUTES from "../../routes";
import Breadcrumb from "../../Common/Breadcrumb";
import Cards from "../../Common/Cards";
import moment from "moment";
import LoadImage from "../../Common/LoadImage";
import AuthActions from "../../Redux/action/auth";
import * as _ from "lodash";

const Cart: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [cartData, setCartData] = useState<any>([]);
  const [couponInfo, setCouponInfo] = useState<any>(null);
  const [isFreeShipping, setIsFreeShipping] = useState(false);
  const [shippingCharge, setShippingCharge] = useState<number>(0);
  const [couponDiscount, setCouponDiscount] = useState<any>(null);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [mainTotal, setMainTotal] = useState<number>(0);
  const [showShipping, setShowShipping] = useState<boolean>(false);
  const [countriesData, setCountriesData] = useState<any>([]);
  const [statesData, setStatesData] = useState<any>([]);
  const [cityData, setCityData] = useState<any>([]);
  const [isCheckout, setIsCheckout] = useState<boolean>(false);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [shippingType, setShippingType] = useState<string>("");
  const [shippingTypeList, setShippingTypeList] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [selectedState, setSelectedState] = useState<any>(null);
  const [cityTotal, setCityTotal] = useState<any>(0);
  const authState = useSelector((state: any) => state.auth);
  const cartState = useSelector((state: any) => state.cart);
  const { userInfo } = authState;
  const { cartId } = cartState;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const defaultCountry: any = DEFAULT_COUNTRY;

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "My Cart",
      path: "#",
    },
  ];

  const getCountry = async () => {
    await getCountries(setCountriesData);
  };

  const onCountrySelect = (country: string) => {
    getStates(country);
  };

  const url = "all=true&sort=id:asc";

  const getStates = async (countryId: string) => {
    await CommonService.getState(countryId, url)
      .then((res: any) => {
        if (res && res?.data) {
          setStatesData(res?.data);
        }
      })
      .catch(() => {
        setStatesData([]);
      });
  };

  const onStateSelect = (state: string) => {
    setSelectedState(state);
    setPage(1);
    getCity(1, state);
  };

  const getCity = async (
    pageNumber: number,
    stateId: string,
    search?: string
  ) => {
    let cityUrl = `pagination[page]=${pageNumber}&pagination[pageSize]=50&sort=id:asc`;
    if (search) {
      cityUrl += `&search=${search}`;
    }
    await CommonService.getCity(stateId, cityUrl)
      .then((res: any) => {
        let arr: any = [];
        if (search || pageNumber === 1) {
          setCityData([]);
          arr = [...res?.data];
        } else {
          arr = [...cityData, ...res?.data];
        }
        setCityData(arr);
        if (!cityTotal) {
          setCityTotal(res?.meta?.total);
        }
      })
      .catch(() => {
        setCityData([]);
      });
  };

  const calculateSubTotal = (cartItems: any) => {
    return cartItems.reduce(
      (total: number, item: { quantity: number; product: { price: number } }) =>
        total + item.quantity * item.product.price,
      0
    );
  };

  const calculateDiscount = (
    disType: string,
    amount: number,
    cartSubTotal: number
  ) => {
    switch (disType) {
      case DISCOUNT_TYPE.FIXED:
        setCouponDiscount(amount);
        break;
      case DISCOUNT_TYPE.PERCENTAGE:
        let dis = (cartSubTotal * amount) / 100;
        setCouponDiscount(dis);
        break;
      case DISCOUNT_TYPE.PER_PRODUCT:
        setCouponDiscount(amount * cartData?.length);
        break;
      default:
        break;
    }
  };

  const setCouponDetails = (couponObj: any, cartSubTotal: number) => {
    setCouponInfo(couponObj);
    setIsFreeShipping(couponObj?.couponFreeShipping);
    calculateDiscount(
      couponObj?.discountType,
      couponObj?.discountAmount,
      cartSubTotal
    );
  };

  const checkOtherCartInfo = (cartObj: any, cartSubTotal: number) => {
    if (cartObj?.cart_coupon) {
      if (moment() > moment(cartObj?.cart_coupon?.couponExpDate, DATE_FROMAT)) {
        NotificationWithIcon("error", VALIDATIONS.COUPON_EXPIRED);
      }
      setCouponDetails(cartObj?.cart_coupon, cartSubTotal);
    }
  };

  const removeCoupon = async (couponId: number) => {
    setLoader(true);
    await CartService.removeCoupon(couponId)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setCouponInfo(null);
          setCouponDiscount(null);
        }
      })
      .catch((error: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          error?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const removeShipping = async (shippingId: number) => {
    setLoader(true);
    await CartService.removeShipping(shippingId)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setShippingCharge(0);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const onDeleteAllItem = async (data: any) => {
    if (data?.cart_items?.length === 0 && data?.cart_coupon) {
      await removeCoupon(data?.cart_coupon?.id);
    }
    if (data?.cart_items?.length === 0 && data?.cart_shipping) {
      await removeShipping(data?.cart_shipping?.id);
    }
  };

  const getCart = async (isDelete: boolean, showLoader = true) => {
    if (showLoader) {
      setLoader(true);
    }
    const isAuthenticated = isUserLoggedIn();
    let id: string = isAuthenticated ? userInfo?.id : UUID();
    await CartService.getCart(id)
      .then(async (res: any) => {
        setLoader(false);
        if (res && res?.status === "Success") {
          if (res?.data) {
            setCartData(res?.data?.cart_items);
            const cartSubTotal: any = formatPrice(
              calculateSubTotal(res?.data?.cart_items)
            );
            setSubTotal(cartSubTotal);
            dispatch(
              CartActions.setCartId({
                cartId: res?.data?.id,
              })
            );
            dispatch(
              CartActions.setTotalItems({
                totalItems: res?.data?.cart_items.length,
              })
            );
            checkOtherCartInfo(res?.data, cartSubTotal);
            if (isDelete) {
              onDeleteAllItem(res?.data);
            }
          } else {
            setCartData([]);
            NotificationWithIcon("error", VALIDATIONS.APPLY_CODE_ERROR);
          }
        } else {
          NotificationWithIcon("error", res?.message);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        setCartData([]);
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getUserCart() {
      await getCart(false);
      await getCountry();
      await getStates(userInfo?.shippingCountry?.id || defaultCountry.id);
      setSelectedState(userInfo?.shippingState?.id || "");
      if (userInfo?.shippingStreetAddress && cartId) {
        const req = {
          data: {
            cart: cartId,
            streetAddress: userInfo?.shippingStreetAddress,
            country: userInfo?.shippingCountry?.id,
            state: userInfo?.shippingState?.id,
            city: userInfo?.shippingCity?.id,
            zipcode: userInfo?.shippingZipCode,
          },
        };
        await onSaveShippingAddress(req, false);
      }
    }
    getUserCart();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [subTotal, couponDiscount, shippingCharge]);

  const updateQuantity = async (value: number, productId: number) => {
    try {
      let updated = cartData.map((i: any) => {
        return i?.product?.id === productId ? { ...i, quantity: value } : i;
      });
      let reqObj: any = {
        data: {
          cart: cartId,
          product: productId,
          quantity: value,
          isUpdate: true,
        },
      };
      setLoader(true);
      await CartService.addUpdateCartItem(reqObj)
        .then((res: any) => {
          if (res && res?.status === "Success") {
            setLoader(false);
            setCartData(updated);
            const cartSubTotal: any = formatPrice(calculateSubTotal(updated));
            setSubTotal(cartSubTotal);
          }
        })
        .catch((err: any) => {
          setLoader(false);
          NotificationWithIcon("error", err?.data?.error?.message);
        });
    } catch (error) {
      let err: any = error;
      NotificationWithIcon("error", err);
    }
  };

  const onDeleteItem = async (cartItemId: any) => {
    setLoader(true);
    await CartService.deleteCartItem(cartItemId)
      .then(async (res: any) => {
        if (res && res?.status === "Success") {
          NotificationWithIcon("success", VALIDATIONS.CART_ITEM_DELETE);
          await getCart(true, false);
        }
      })
      .catch((err: any) => {
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
        setLoader(false);
      });
  };

  const onApplyCode = async (values: { code: string }, resetForm: any) => {
    if (cartId && cartData?.length) {
      const req = {
        data: {
          cart: cartId,
          coupon: values.code,
        },
      };
      setLoader(true);
      await CartService.applyCoupon(req)
        .then((res: any) => {
          if (res && res?.status === "Success") {
            setLoader(false);
            setCouponDetails(res?.data, subTotal);
            NotificationWithIcon("success", VALIDATIONS.APPLY_CODE_SUCCESS);
          }
        })
        .catch((err: any) => {
          NotificationWithIcon(
            "error",
            err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
          );
          setLoader(false);
          resetForm();
        });
    } else {
      NotificationWithIcon("error", VALIDATIONS.APPLY_CODE_ERROR);
    }
  };

  const calculateTotal = () => {
    if (couponInfo && !isFreeShipping) {
      setMainTotal(formatPrice(subTotal - couponDiscount + shippingCharge));
    } else if (couponInfo && isFreeShipping) {
      setMainTotal(formatPrice(subTotal - couponDiscount));
    } else if (!couponInfo && !isFreeShipping) {
      setMainTotal(formatPrice(subTotal + shippingCharge));
    } else {
      setMainTotal(formatPrice(subTotal));
    }
  };

  const handleClose = () => {
    setShowShipping(false);
  };

  const onSaveShippingAddress = async (req: any, changeAddress: boolean) => {
    setLoader(true);
    await CartService.saveShippingDetails(req)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setShippingCharge(
            res?.data?.shippingCharge ? Number(res?.data?.shippingCharge) : 0
          );
          setShippingType(res?.data?.shippingType);
          setShippingTypeList(res?.data?.shippingTypes);
          setLoader(false);
          handleClose();
          NotificationWithIcon("success", res?.message);
          if (changeAddress) {
            const selectedCountry = _.find(
              countriesData,
              (item) => item.id === req.data.country
            );
            const selectedState = _.find(
              statesData,
              (item) => item.id === req.data.state
            );
            const selectedCity = _.find(
              cityData,
              (item) => item.id === req.data.city
            );
            const updatedInfo = {
              ...userInfo,
              shippingStreetAddress: req.data.streetAddress,
              shippingCountry: selectedCountry,
              shippingState: selectedState,
              shippingCity: selectedCity,
              shippingZipCode: req.data.zipcode,
            };
            dispatch(
              AuthActions.setUserInfo({
                userInfo: updatedInfo,
              })
            );
          }
        }
      })
      .catch((er: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          er?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const onCheckout = async () => {
    if (!isUserLoggedIn()) {
      navigate(`${ROUTES.HOME}?fromPage=cart`);
      return;
    }
    if (!userInfo?.billingStreetAddress || !userInfo?.shippingStreetAddress) {
      NotificationWithIcon("error", VALIDATIONS.BILLING_SHIPPING_REQUIRED);
      return;
    }
    if (!isCheckout) {
      setIsCheckout(true);
      return;
    }
    if (!selectedCard) {
      NotificationWithIcon("error", VALIDATIONS.CARD_SELECTION_REQUIRED);
      return;
    }
    setLoader(true);
    const req = {
      data: {
        cart: cartId,
        card: selectedCard,
        shippingType,
      },
    };
    await CartService.placeOrder(req)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", res?.message);
          dispatch(CartActions.Reset());
          navigate(ROUTES.MYORDERS);
        }
      })
      .catch((er: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          er?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const navigateProductDetail = (productId: number) => {
    navigate(`/product/${productId}`);
  };

  const onShippingTypeChange = async (e: any) => {
    setShippingType(e.target.value);
    const req = {
      data: {
        cart: cartId,
        streetAddress: userInfo?.shippingStreetAddress,
        country: userInfo?.shippingCountry?.id,
        state: userInfo?.shippingState?.id,
        city: userInfo?.shippingCity?.id,
        zipcode: userInfo?.shippingZipCode,
        shippingType: e.target.value,
      },
    };
    await onSaveShippingAddress(req, false);
  };

  const onCityScroll = async (e: any) => {
    if (cityTotal === cityData?.length) {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPage((page) => page + 1);
      await getCity(page + 1, selectedState);
    }
  };

  const onSearchCity = (searchValue: string, setFieldValue: any) => {
    setPage(1);
    setFieldValue("city", searchValue);
    if (searchValue !== "") {
      getCity(1, selectedState, searchValue);
    } else {
      getCity(1, selectedState);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="My Cart" />
      <div className="container">
        <div className="cart--page mb-5">
          <div className="left">
            {!isCheckout ? (
              <table className="table cart--product-table">
                <tbody>
                  {cartData &&
                    cartData?.length > 0 &&
                    cartData.map((cartItem: any) => {
                      const cartProductId = cartItem?.product?.id;
                      return (
                        <tr key={cartItem?.id} className="cart-table">
                          <td>
                            <div className="product-detail-box">
                              <LoadImage
                                src={cartItem?.product?.images?.[0]?.url}
                                onClick={() =>
                                  navigateProductDetail(cartProductId)
                                }
                                inlineClass="img-cursor"
                              />
                              <span
                                onClick={() =>
                                  navigateProductDetail(cartProductId)
                                }
                              >
                                {cartItem?.product?.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Quantity
                              totalQuantity={cartItem?.product?.totalQuantity}
                              quantity={cartItem?.quantity}
                              updateQuantity={(value: number) =>
                                updateQuantity(value, cartItem?.product?.id)
                              }
                            />
                          </td>
                          <td className="notranslate">
                            $
                            {formatPrice(
                              cartItem?.product?.price * cartItem?.quantity
                            )}
                          </td>
                          <td>
                            <img
                              src={removeicon}
                              alt=""
                              onClick={() => onDeleteItem(cartItem?.id)}
                              className="img-cursor"
                            />
                          </td>
                        </tr>
                      );
                    })}
                  {cartData?.length === 0 && (
                    <>
                      <div className="mb-4 no-address--info text-center mt-3">
                        <h4 className="no-address-txt">No items in Cart</h4>
                        <p className="mb-3">
                          Looks like you haven't added anything to your cart.
                        </p>
                        <button
                          className="btn btn-borderd--primary py-2"
                          onClick={() => navigate(ROUTES.PRODUCT_CATEGORY)}
                        >
                          Continue Shopping
                        </button>
                      </div>
                    </>
                  )}
                </tbody>
              </table>
            ) : (
              <Cards
                title="My Card"
                setLoader={(value: boolean) => setLoader(value)}
                fromCart={true}
                handleCard={(id: number) => setSelectedCard(id)}
              />
            )}
          </div>
          <div className="right">
            <h4 className="block-title block-title-md font-weight-semibold">
              Cart Summary
            </h4>
            <div className="card custom-card">
              <div className="card-body custom-field">
                <Formik
                  initialValues={{
                    code: couponInfo ? couponInfo?.coupon : "",
                  }}
                  validationSchema={couponValidation}
                  onSubmit={(values: any, { resetForm }) => {
                    onApplyCode(values, resetForm);
                  }}
                  enableReinitialize={true}
                >
                  {({ handleSubmit, values, resetForm }) => (
                    <Form form={form}>
                      <div className="ant-form-item-label">
                        <label>Promo Code</label>
                        <div className="text-input-group">
                          <Field
                            name="code"
                            className="form-control custom-form-control mt-2"
                            placeholder="Enter code"
                            value={values.code}
                          />
                          {couponInfo ? (
                            <Link
                              to="#"
                              className="apply-float-link"
                              onClick={() => {
                                removeCoupon(couponInfo?.id);
                                resetForm();
                              }}
                            >
                              Remove
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="apply-float-link"
                              onClick={() => handleSubmit()}
                            >
                              Apply
                            </Link>
                          )}
                        </div>
                        {getErrorMessage("code")}
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
            <div className="card custom-card mt-3">
              <div className="card-body custom-field">
                <div className="ant-form-item-label d-flex mb-2">
                  <label className="me-auto">Shipping</label>
                  <Link
                    to="#"
                    className="a-r-o-link text-decoration"
                    onClick={() => {
                      if (cartId && cartData?.length) {
                        setShowShipping(true);
                        if (userInfo?.shippingStreetAddress) {
                          setCityData([userInfo?.shippingCity]);
                        }
                      } else {
                        NotificationWithIcon(
                          "error",
                          VALIDATIONS.APPLY_CODE_ERROR
                        );
                      }
                    }}
                  >
                    Calculate Shipping
                  </Link>
                </div>
                {shippingCharge ? (
                  <div className="ant-form-item-label d-flex">
                    <Radio.Group
                      value={shippingType}
                      onChange={onShippingTypeChange}
                    >
                      {shippingTypeList?.length > 0 &&
                        shippingTypeList.map((type: any) => {
                          return (
                            <>
                              <Radio
                                value={type?.code}
                                className="me-auto text--grey"
                                style={{ width: "80%", position: "relative" }}
                              >
                                {type?.name}:
                              </Radio>
                              <label
                                className="ms-auto mt-auto"
                                style={{ float: "right", position: "absolute" }}
                              >
                                {type?.code === shippingType
                                  ? `$${shippingCharge}`
                                  : ""}
                              </label>
                            </>
                          );
                        })}
                    </Radio.Group>
                  </div>
                ) : (
                  <em className="cmn-txt cmn-txt-13 text--grey">
                    No shipping options were found for Middletown, MA 21769.
                  </em>
                )}
              </div>
            </div>
            <div className="card custom-card mt-3">
              <div className="card-body custom-field p-0">
                <div className="p-3">
                  <div className="ant-form-item-label d-flex">
                    <label className="me-auto text--grey">Sub Total</label>
                    <label className="notranslate">${subTotal}</label>
                  </div>
                  {couponInfo && (
                    <div className="ant-form-item-label d-flex">
                      <label className="me-auto text--grey">Promo Code</label>
                      <label className="notranslate">- ${couponDiscount}</label>
                    </div>
                  )}
                  {!isFreeShipping && (
                    <div className="ant-form-item-label d-flex">
                      <label className="me-auto text--grey">
                        Shipping Charge
                      </label>
                      <label className="notranslate">
                        ${shippingCharge || 0}
                      </label>
                    </div>
                  )}
                </div>
                <div className="hr--divider"></div>
                <div className="p-3">
                  <div className="ant-form-item-label d-flex">
                    <label className="me-auto">Total Amount</label>
                    <label className="notranslate">${mainTotal}</label>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="btn btn--primary btn--primary__custom mt-3 w-100"
              onClick={onCheckout}
              disabled={cartData?.length === 0}
            >
              CHECKOUT
            </button>
          </div>
        </div>
      </div>

      {/* Shipping Modal  */}
      <Modal
        show={showShipping}
        onHide={handleClose}
        className="custom-modal custom-modal-md"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Calculate Shipping</Modal.Title>
        </Modal.Header>
        <Modal.Body className="calculate-shipping-modal">
          <Container fluid className="px-0">
            <Formik
              initialValues={{
                shippingStreetAddress: userInfo?.shippingStreetAddress || "",
                shippingCountry:
                  userInfo?.shippingCountry?.id || defaultCountry?.id,
                shippingState: userInfo?.shippingState?.id || "",
                shippingCity: userInfo?.shippingCity?.id || "",
                shippingZipcode: userInfo?.shippingZipCode || "",
              }}
              onSubmit={(values) => {
                const req = {
                  data: {
                    cart: cartId,
                    streetAddress: values.shippingStreetAddress,
                    country: values.shippingCountry,
                    state: values.shippingState,
                    city: values.shippingCity,
                    zipcode: values.shippingZipcode,
                    shippingType,
                  },
                };
                onSaveShippingAddress(req, true);
              }}
              validationSchema={shippingValidation}
              validateOnBlur={false}
              enableReinitialize={true}
            >
              {({ setFieldValue, values, handleSubmit }) => (
                <Form
                  layout="vertical"
                  form={form}
                  onSubmitCapture={handleSubmit}
                >
                  <Row>
                    <Col xl={24}>
                      <Form.Item
                        label="Street address*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="shippingStreetAddress"
                          name="shippingStreetAddress"
                          placeholder="House number and street name"
                          className="form-control custom-form-control"
                          maxLength={MAX_STREET_ADDRESS_LENGTH}
                        />
                        {getErrorMessage("shippingStreetAddress")}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="Country / Region *"
                        className="custom-field mb-3"
                      >
                        <Select
                          popupClassName="custom--select"
                          placeholder="Select country / region"
                          options={countriesData?.map((item: any) => ({
                            label: item?.name,
                            value: item?.id,
                          }))}
                          showSearch
                          onChange={(e) => {
                            setFieldValue("shippingCountry", e);
                            onCountrySelect(e);
                            setFieldValue("shippingState", "");
                            setFieldValue("shippingCity", "");
                            setCityData([]);
                          }}
                          value={values.shippingCountry}
                          filterOption={(input: any, option: any) =>
                            filterData(input, option)
                          }
                        />
                        {getErrorMessage("shippingCountry")}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item label="State*" className="custom-field mb-3">
                        <Select
                          showSearch
                          popupClassName="custom--select"
                          placeholder="Select state"
                          options={statesData?.map((item: any) => ({
                            label: item?.name,
                            value: item?.id,
                          }))}
                          onChange={(e) => {
                            onStateSelect(e);
                            setFieldValue("shippingState", e);
                            setFieldValue("shippingCity", "");
                          }}
                          value={
                            values.shippingState === ""
                              ? undefined
                              : values.shippingState
                          }
                          filterOption={(input: any, option: any) =>
                            filterData(input, option)
                          }
                        />
                        {getErrorMessage("shippingState")}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Item
                        label="Town / City*"
                        className="custom-field mb-3"
                      >
                        <Select
                          showSearch
                          popupClassName="custom--select"
                          placeholder="Select town / city"
                          value={
                            values.shippingCity === ""
                              ? undefined
                              : values.shippingCity
                          }
                          options={cityData?.map((item: any) => ({
                            label: item?.name,
                            value: item?.id,
                          }))}
                          onChange={(e) => {
                            setFieldValue("shippingCity", e);
                          }}
                          filterOption={(input: any, option: any) =>
                            filterData(input, option)
                          }
                          onPopupScroll={onCityScroll}
                          onSearch={(e: any) => onSearchCity(e, setFieldValue)}
                        />
                        {getErrorMessage("shippingCity")}
                      </Form.Item>
                    </Col>
                    <Col>
                      <Form.Item
                        label="ZIP Code*"
                        className="custom-field mb-3"
                      >
                        <Field
                          id="shippingZipcode"
                          name="shippingZipcode"
                          className="form-control custom-form-control"
                          placeholder="Enter zip code"
                          validate={validateZipcode}
                        />
                        {getErrorMessage("shippingZipcode")}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item className="mt-3">
                    <button
                      type="submit"
                      className="btn--primary btn w-600 w-100 btn--primary btn w-180"
                    >
                      Save
                    </button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Cart;
