import * as Constants from "./constants";
import Cookie from "js-cookie";
import { Form, notification } from "antd";
import { ErrorMessage, Field } from "formik";
import { classNames } from "primereact/utils";
import { v4 as uuidv4 } from "uuid";
import AuthActions from "../Redux/action/auth";
import { store } from "../Redux/store";
import CartService from "../API/Cart";
import CartActions from "../Redux/action/cart";
import ROUTES from "../routes";
import visalogo from "../assets/images/visa-card-logo.png";
import mastercardlogo from "../assets/images/mastercard-logo.svg.png";
import amexlogo from "../assets/images/american-express-card-logo.png";
import discoverlogo from "../assets/images/discover-card-logo.png";
import jwt_decode from "jwt-decode";
import CommonService from "../API/Common";

const imageUrl = process.env.REACT_APP_IMAGE_URL;

export declare type NotificationPlacement =
  | "topLeft"
  | "topRight"
  | "bottomLeft"
  | "bottomRight";

// Notification Show
export const NotificationWithIcon = (
  type: string,
  message: string,
  placement: NotificationPlacement = "bottomRight"
): void => {
  if (type === "success") {
    notification.success({
      message,
      placement,
    });
  } else if (type === "error") {
    notification.error({
      message,
      placement,
    });
  } else {
    notification.info({
      message,
      placement,
    });
  }
};

export const getUserToken = () => {
  const { AUTH_TOKEN } = Constants;
  return Cookie.get(AUTH_TOKEN);
};

export const isUserLoggedIn = () => {
  const token = getUserToken();
  if (token) {
    return true;
  }
  return false;
};

export const convertToUrlFormat = (str:any) =>{
 return str
      .toLowerCase()                
      .replace(/\s+/g, '-')         
      .replace(/[^\w-]+/g, '')     
      .replace(/--+/g, '-')         
      .replace(/^-+/, '')      
      .replace(/-+$/, ''); 
}

export const getProductUrl = (id:any, productName:any) => {
  const formattedName = convertToUrlFormat(productName);
  return `${id}/${formattedName}`;
}


export const getCategoryUrl = (id:any, CategoryName:any) => {
  const categoryName = convertToUrlFormat(CategoryName);
  return `${id}/${categoryName}`;
}

export const getReadOnlyProductUrl = (id:any,ProductUrl:any) => {
  const readonlyPoduct = convertToUrlFormat(ProductUrl);
  return `${id}/${readonlyPoduct}`
}


export const checkPasswordChar = (password: string) => {
  let regex = new RegExp(Constants.PASSWORD_REGEX);
  return regex.test(password);
};

export const passwordForm = () => {
  return (
    <>
      <Form.Item label="New Password" className="custom-field mb-3">
        <Field
          id="newPassword"
          name="newPassword"
          placeholder="Enter new password"
          type="password"
          className="form-control custom-form-control"
        />
        <ErrorMessage
          name="newPassword"
          component="span"
          className="error-msg"
        />
      </Form.Item>
      <Form.Item label="Confirm Password" className="custom-field mb-3">
        <Field
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Enter confirm password"
          type="password"
          className="form-control custom-form-control"
        />
        <ErrorMessage
          name="confirmPassword"
          component="span"
          className="error-msg"
        />
      </Form.Item>
      <Form.Item>
        <button
          type="submit"
          className="btn--primary btn--primary-md btn--primary--200 w-100 btn mt-2"
        >
          Save
        </button>
      </Form.Item>
    </>
  );
};

export const UUID = () => {
  const browserId = localStorage.getItem("browserId");
  if (browserId) {
    return browserId;
  } else {
    const newBrowserId = uuidv4();
    localStorage.setItem("browserId", newBrowserId);
    return newBrowserId;
  }
};

export const handleLogout = () => {
  Cookie.remove(Constants.AUTH_TOKEN);
  store.dispatch(AuthActions.Reset());
  store.dispatch(CartActions.Reset());
};

export const addUpdateCardItem = async (
  req: any,
  navigate: any,
  setLoader: any
) => {
  await CartService.addUpdateCartItem(req)
    .then((res: any) => {
      if (res && res?.status === "Success") {
        setLoader(false);
        if (navigate) {
          navigate(ROUTES.MYCART);
        }
      }
    })
    .catch((err: any) => {
      setLoader(false);
      NotificationWithIcon(
        "error",
        err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
      );
    });
};

export const createCart = async (setLoader: any) => {
  let authState = store.getState().auth;
  const { userInfo } = authState;

  let obj: any = null;
  if (isUserLoggedIn()) {
    obj = { data: { userId: userInfo?.id } };
  } else {
    obj = { data: { browserId: UUID() } };
  }
  return new Promise(async (resolve, reject) => {
    setLoader(true);
    await CartService.createCart(obj)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          store.dispatch(
            CartActions.setCartId({
              cartId: res?.data?.id,
            })
          );
          resolve(res?.data?.id);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        reject(err);
      });
  });
};

export const validateContact = (value: any) => {
  let errorMessage;
  let regex = new RegExp(Constants.CONTACT_REGEX);
  if (!regex.test(value)) {
    errorMessage = Constants.VALIDATIONS.INVALID_CONTACT;
  }
  return errorMessage;
};

export const validateZipcode = (value: any) => {
  let errorMessage;
  let regex = new RegExp(Constants.ZIPCODE_REGEX);
  if (!regex.test(value)) {
    errorMessage = Constants.VALIDATIONS.INVALID_ZIPCODE;
  }
  return errorMessage;
};

export const checkPasswordWithOld = (newPass: string, oldPass: string) => {
  return newPass === oldPass;
};

export const getErrorMessage = (name: string) => {
  return <ErrorMessage name={name} component="span" className="error-msg" />;
};

export const geturl = (item: string) => {
  return `${imageUrl}${item}`;
};

export const cardLogoMapping = [
  {
    cardType: "mastercard",
    imagePath: mastercardlogo,
  },
  {
    cardType: "visa",
    imagePath: visalogo,
  },
  {
    cardType: "amex",
    imagePath: amexlogo,
  },
  {
    cardType: "discover",
    imagePath: discoverlogo,
  },
];

export const paginationTemplate = {
  layout: "PrevPageLink PageLinks NextPageLink",
  PrevPageLink: (options: any) => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="p-3">Prev</span>
      </button>
    );
  },
  NextPageLink: (options: any) => {
    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
        disabled={options.disabled}
      >
        <span className="p-3">Next</span>
      </button>
    );
  },
  PageLinks: (options: any) => {
    if (
      (options.view.startPage === options.page &&
        options.view.startPage !== 0) ||
      (options.view.endPage === options.page &&
        options.page + 1 !== options.totalPages)
    ) {
      const className = classNames(options.className, { "p-disabled": true });

      return (
        <span className={className} style={{ userSelect: "none" }}>
          ...
        </span>
      );
    }

    return (
      <button
        type="button"
        className={options.className}
        onClick={options.onClick}
      >
        {options.page + 1}
      </button>
    );
  },
};

export const decodedJwt = (token: string) => {
  if (token) {
    try {
      return jwt_decode(token);
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const getCountries = async (setCountriesData: any) => {
  const list: any = localStorage.getItem("countryList");
  if (!list) {
    await CommonService.getCountry()
      .then((res: any) => {
        if (res && res?.data) {
          localStorage.setItem("countryList", JSON.stringify(res?.data));
          setCountriesData(res?.data);
        }
      })
      .catch((err: any) => {
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  } else {
    setCountriesData(JSON.parse(list));
  }
};

export const formatPrice = (price: any) => {
  return Number(Number(price).toFixed(Constants.PRICE_DECIMAL_LIMIT));
};

export const filterData = (input: any, option: any) => {
  return (option?.label ?? "").toLowerCase().includes(input?.toLowerCase());
};
