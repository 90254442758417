import axios, { AxiosRequestConfig } from "axios";
/**
 * Create an Axios Client with defaults
 */
const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Request Wrapper with default success/error actions
 */
const request = (options: AxiosRequestConfig) => {
  const onSuccess = (response: { headers: any; data: any }) => {
    return response.data;
  };
  const onError = (error: {
    config: any;
    response: { status: any; data: any; headers: any };
    message: any;
  }) => {
    return Promise.reject(error.response || error.message);
  };
  return client(options).then(onSuccess).catch(onError);
};

export default request;
