import { FC, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import { Form, Row } from "antd";
import { Col, Modal } from "react-bootstrap";
import AuthService from "../../API/Auth";
import { changePasswordValidations } from "../../Utils/validations";
import {
  handleLogout,
  NotificationWithIcon,
  passwordForm,
} from "../../Utils/helper";
import SidebarLayout from "../../Common/SideBarLayout";
import Loader from "../../Common/Loader";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../routes";
import { VALIDATIONS } from "../../Utils/constants";
import { useSelector } from "react-redux";
import alertIcon from "../../assets/images/alert.svg";
import banicon from "../../assets/images/ban-sign-icon.svg";

const ChangePassword: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const authState = useSelector((state: any) => state.auth);
  const { isSocialLogin } = authState;
  const [showAlert, setShowAlert] = useState<boolean>(isSocialLogin);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onSave = async (
    values: {
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
    },
    resetForm: any
  ) => {
    setLoader(true);
    let data = {
      currentPassword: values.oldPassword,
      password: values.newPassword,
      passwordConfirmation: values.confirmPassword,
    };
    await AuthService.changePassword(data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", res?.message);
          resetForm();
          handleLogout();
          setTimeout(() => {
            navigate(ROUTES.HOME);
          }, 1000);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const handleOk = () => {
    setShowAlert(false);
  };

  return (
    <>
      {loader && <Loader />}
      <SidebarLayout activeTab="changePassword">
        <Row>
          <Col xs={12}>
            <h2 className="block-title block-title-xl-22 mb-3">
              Change Password
            </h2>
            <div className="hr--divider mb-3"></div>
          </Col>
          {!isSocialLogin ? (
            <Col className="col-lg-6 col-12">
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                onSubmit={(values, { resetForm }) => {
                  onSave(values, resetForm);
                }}
                validationSchema={changePasswordValidations}
              >
                {({ handleSubmit }: any) => (
                  <Form
                    layout="vertical"
                    form={form}
                    onSubmitCapture={handleSubmit}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Form.Item
                      label="Old Password"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="oldPassword"
                        name="oldPassword"
                        placeholder="Enter old password"
                        type="password"
                        className="form-control custom-form-control"
                      />
                      <ErrorMessage
                        name="oldPassword"
                        component="span"
                        className="error-msg"
                      />
                    </Form.Item>
                    {passwordForm()}
                  </Form>
                )}
              </Formik>
            </Col>
          ) : (
            <>
              <Col xs={12}>
                <div className="mb-4 no-address--info text-center mt-3">
                  <h3 className="no-address-txt d-flex flex-column align-items-center">
                    <img src={banicon} alt="" className="mb-2" />
                    You can not access this page as Social login user.
                  </h3>
                </div>
              </Col>
              <Modal
                show={showAlert}
                onHide={handleOk}
                className="changePinModal"
                centered
                backdrop="static"
              >
                <Modal.Header className="justify-content-center">
                  <Modal.Title>
                    <img src={alertIcon} alt="" className="w-auto mr-0" />
                    warning
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <>
                      <Row gutter={16} className="flex-column">
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="mb-3"
                        >
                          <div className={` border-0`}>
                            <div>
                              <div className="text-center">
                                <label>
                                  You can not access this page as Social login
                                  user.
                                </label>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col
                          xl={24}
                          lg={24}
                          md={24}
                          sm={24}
                          xs={24}
                          className="d-flex justify-content-center"
                        >
                          <button
                            onClick={handleOk}
                            className="btn--primary btn btn-sm py-1"
                          >
                            Ok
                          </button>
                        </Col>
                      </Row>
                    </>
                  </div>
                </Modal.Body>
              </Modal>
            </>
          )}
        </Row>
      </SidebarLayout>
    </>
  );
};

export default ChangePassword;
