// #region Global Imports
import { configureStore } from "@reduxjs/toolkit";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

// #region Local Imports
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import Reducers from "./reducers";
// #endregion Local Imports

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "cart"], // which reducer want to store
};

const logMiddleware: any = createLogger({
  predicate: () => process.env.NODE_ENV !== "production",
});
const pReducer = persistReducer(persistConfig, Reducers);

const store = configureStore({
  reducer: pReducer,
  middleware: [thunkMiddleware, logMiddleware],
});
const persistor = persistStore(store);

export { persistor, store };
