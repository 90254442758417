/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Image, Tab, Tabs } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProductService from "../../API/Product";
import Slider from "react-slick";
import Loader from "../../Common/Loader";
import {
  addUpdateCardItem,
  getProductUrl,
  createCart,
  isUserLoggedIn,
  NotificationWithIcon,
} from "../../Utils/helper";
import ROUTES from "../../routes";
import Quantity from "../../Common/Quantity";
import * as Constants from "../../Utils/constants";

import { useSelector } from "react-redux";
import Breadcrumb from "../../Common/Breadcrumb";
import moment from "moment";
import LoadImage from "../../Common/LoadImage";

interface ProductImage {
  original: string;
  thumbnail: string;
}

interface ProductReview {
  id: number;
  reviewDescription: string;
  date: string;
  username: string;
}

const ProductDetail = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [productInfo, setProductInfo] = useState<any>(null);
  const [productImages, setProductImages] = useState<Array<ProductImage>>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [nav1, setNav1] = useState<any>(null);
  const [nav2, setNav2] = useState<any>(null);
  const [reviewMsg, setReviewMsg] = useState<string>("");
  const [reviewError, setReviewError] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [reviews, setReviews] = useState<Array<ProductReview>>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [showMoreSelected, setShowMoreSelected] = useState<boolean>(false);
  const [relatedProducts, setRelatedProducts] = useState<any>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const cartState = useSelector((state: any) => state.cart);
  const authState = useSelector((state: any) => state.auth);
  const { cartId } = cartState;
  const { userInfo } = authState;

  const getError = (err: any) => {
    setLoader(false);
    NotificationWithIcon(
      "error",
      err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
    );
  };

  const getProductDetails = async () => {
    setLoader(true);
    let queryParams =
      "populate[images][fields]=url&populate[category][fields]=title";
    if (cartId) {
      queryParams += `&cartId=${cartId}`;
    }
    await ProductService.getProductDetails(Number(id), queryParams)
      .then(async (res: any) => {
        if (res && res?.status === "Success") {
          setProductInfo(res?.data); 

          const productImg = res?.data?.images;
          if (productImg?.length > 0) {
            const images = productImg?.map((i: any) => {
              return {
                original: i?.url,
              };
            });
            setProductImages(images);
          }
          if (res?.data?.isAddedInCart) {
            setQuantity(res?.data?.cartQuantity);
          }
          await getRelatedProducts(res?.data?.category?.id);
          setLoader(false);
        }
      })
      .catch((err: any) => {
        getError(err);
      });
  };

  const getProductReview = async (pageNumber: number) => {
    setLoader(true);
    const queryParams = `start=${pageNumber}&limit=${Constants.REVIEW_PAGESIZE}&sort=createdAt:desc`;
    await ProductService.getProductReviewList(Number(id), queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          if (res?.data?.length > 0) {
            const reviewList = res?.data?.map((i: any) => {
              return {
                id: i.id,
                reviewDescription: i.reviewDescription,
                date: i.createdAt,
                username: i.user.username,
              };
            });
            let reviewArr: any = [];
            if (showMoreSelected) {
              reviewArr = [...reviews, ...reviewList];
            } else {
              setReviews([]);
              reviewArr = [...reviewList];
            }
            setReviews(reviewArr);
            setShowMoreSelected(false);
          }
          setTotalCount(res?.meta?.total);
          setLoader(false);
        }
      })
      .catch(() => {
        setLoader(false);
      });
  };

  const getRelatedProducts = async (categoryId: number) => {
    setLoader(true);
    const queryParams = `start=${page}&limit=${Constants?.RELATED_PRODUCTS_PAGESIZE}&populate=*`;
    await ProductService.getProductList(categoryId, queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          const relatedProArr = res?.data?.filter(
            (item: any) => item.id !== Number(id)
          );
          setRelatedProducts(relatedProArr);
          setTotalCount(res?.meta?.total);
        }
        setLoader(false);
      })
      .catch((err: any) => {
        getError(err);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getProductInfo() {
      if (id) {
        await getProductDetails();
        await getProductReview(page);
      }
    }
    getProductInfo();
  }, [id]);

  useEffect(() => {
    if (reviews?.length < totalCount) {
      setShowLoadMore(true);
    }
    if (reviews?.length === totalCount) {
      setShowLoadMore(false);
    }
  }, [reviews]);

  const updateQuantity = (quaValue: number) => {
    setQuantity(quaValue);
  };

  const onAddtoCart = async () => {
    setLoader(true);
    let cartReq: any = {
      data: {
        cart: cartId,
        product: productInfo?.id,
        quantity,
      },
    };
    if (!cartId) {
      await createCart(setLoader)
        .then(async (res: any) => {
          if (res) {
            cartReq = { ...cartReq.data, cart: res };
            let obj = { data: cartReq };
            await addUpdateCardItem(obj, navigate, setLoader);
          }
        })
        .catch((error: any) => {
          NotificationWithIcon(
            "error",
            error?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
          );
        });
    } else {
      let obj = cartReq;
      if (productInfo?.isAddedInCart) {
        if (quantity === productInfo?.cartQuantity) {
          navigate(ROUTES.MYCART);
          return;
        } else {
          cartReq = { ...cartReq.data, isUpdate: true };
          obj = { data: cartReq };
        }
      }
      await addUpdateCardItem(obj, navigate, setLoader);
    }
  };

  const sendReview = async () => {
    if (!reviewMsg.trim() || reviewMsg.trim() === "") {
      setReviewError(true);
    } else {
      const reqObj = {
        data: {
          product: Number(id),
          user: userInfo?.id,
          reviewDescription: reviewMsg.trim(),
        },
      };
      setLoader(true);
      await ProductService.sendProductReview(reqObj)
        .then((res: any) => {
          if (res?.status === "Success") {
            NotificationWithIcon("success", res?.message);
            getProductReview(1);
            setReviewMsg("");
          }
        })
        .catch((err: any) => {
          setLoader(false);
          setReviewMsg("");
          NotificationWithIcon(
            "error",
            err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
          );
        });
    }
  };

  const handleLoadMore = async () => {
    setShowMoreSelected(true);
    setPage((p) => p + 1);
  };

  useEffect(() => {
    if (page > 1) {
      getProductReview(page);
    }
  }, [page]);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "On-Line Store",
      path: ROUTES.PRODUCT_CATEGORY,
    },
    {
      title: "Product Details",
      path: "#",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="On-Line Store" />
      {productInfo && (
        <>
          <div className="container">
            <div className="d-flex align-items-start product--detail-main-block">
              <div className="left w-100">
                <div className="product--detail-slider">
                  <div className="prduct-preview">
                    <Slider
                      asNavFor={nav2}
                      ref={(slider1: any) => setNav1(slider1)}
                    >
                      {productImages?.length > 0 ? (
                        productImages?.map((item: any) => {
                          return (
                            <div>
                              <LoadImage src={item.original} />
                            </div>
                          );
                        })
                      ) : (
                        <Image src={"/assets/images/skyAzul_logo.jpg"} />
                      )}
                    </Slider>
                  </div>
                  <div className="product--detail-thumbnail h-100">
                    <Slider
                      asNavFor={nav1}
                      ref={(slider2: any) => setNav2(slider2)}
                      slidesToShow={4}
                      slidesToScroll={1}
                      swipeToSlide={true}
                      focusOnSelect={true}
                      arrows={true}
                      infinite={false}
                      vertical
                    >
                      {productImages?.length > 0 ? (
                        productImages?.map((item: any) => {
                          return <LoadImage src={item.original} />;
                        })
                      ) : (
                        <Image src={"/assets/images/skyAzul_logo.jpg"} />
                      )}
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="row">
                  <div className="col-12">
                    <h4 className="block-title block-title-xl">
                      {productInfo?.title}
                    </h4>
                    {productInfo?.price > 0 && (
                      <p className="product--price product--price-md">
                        ${productInfo?.price}{" "}
                      </p>
                    )}
                    <div className="hr--divider mt-3" />
                  </div>
                  <div className="col-12 mt-3">
                    <div className="product--detail__info">
                      <div className="table-row">
                        <div className="title">SKU:</div>
                        <div className="info">{productInfo?.SKU}</div>
                      </div>
                      <div className="table-row">
                        <div className="title">Category:</div>
                        <div className="info">
                          <Link to={ROUTES.PRODUCT_CATEGORY}>
                            {" "}
                            {productInfo?.category?.title}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <ul className="list-unstyled cmn-txt text--grey product--detail_table">
                      {/* {productInfo?.weight ||
                        (productInfo?.length &&
                          productInfo?.width &&
                          productInfo?.height && (
                            <li>
                              <table>
                                <tbody>
                                  <tr>
                                    <td>Weight</td>
                                    <td className="notranslate">
                                      {productInfo?.weight
                                        ? `${productInfo?.weight} lbs`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Dimensions</td>
                                    <td className="notranslate">
                                      {productInfo?.length &&
                                        productInfo?.width &&
                                        productInfo?.height
                                        ? `${productInfo?.length} * ${productInfo?.width} * ${productInfo?.height}`
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </li>
                          ))} */}
                      <li
                        dangerouslySetInnerHTML={{
                          __html: productInfo?.productDetail,
                        }}
                      ></li>
                    </ul>
                  </div>
                  {productInfo?.price > 0 && (
                    <div className="col-12 mt-2 d-flex align-items-center">
                      <Quantity
                        totalQuantity={productInfo?.remainingQuantity}
                        quantity={quantity}
                        updateQuantity={updateQuantity}
                      />
                      <button
                        className={`btn btn--primary btn--primary__custom ms-3`}
                        onClick={onAddtoCart}
                      >
                        {productInfo?.isAddedInCart
                          ? "ADDED TO CART"
                          : "ADD TO CART"}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <Tabs
                  defaultActiveKey="home"
                  id="fill-tab-example"
                  className="Prodcut--profile__custom-tab"
                >
                  <Tab eventKey="home" title="Description">
                    <div className="custom--tab-content">
                      <div className="row">
                        <div
                          className="col-12 product-desc-list"
                          dangerouslySetInnerHTML={{
                            __html: productInfo?.productDetailDescription,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="profile" title="Reviews">
                    <div className="custom--tab-content">
                      {reviews?.length > 0 && (
                        <div className="row">
                          <div className="col-12">
                            <ul className="comment--list product--details__comment-list list-unstyled">
                              {reviews.map((review: ProductReview) => {
                                return (
                                  <li key={review?.id}>
                                    <p className="cmnt-txt">
                                      {review?.reviewDescription}
                                    </p>
                                    <p className="author-date-txt">
                                      <span>{review?.username}</span> -{" "}
                                      {moment(review.date).format(
                                        Constants.PRODUCT_REVIEW_DATE_FROMAT
                                      )}
                                    </p>
                                  </li>
                                );
                              })}
                            </ul>
                            {showLoadMore && (
                              <Link
                                to="#s"
                                className="text--primary cmn-txt font-weight-medium"
                                onClick={handleLoadMore}
                              >
                                Load More
                              </Link>
                            )}
                          </div>
                        </div>
                      )}
                      {reviews?.length === 0 && <>There are no reviews yet.</>}
                      {isUserLoggedIn() && (
                        <div className="row mt-4">
                          <div className="col-12">
                            <label className="field--label">
                              Write a Review
                            </label>
                          </div>
                          <div className="col-12">
                            <textarea
                              className="form-control w-100 custom-form-control"
                              placeholder="Type your review here..."
                              rows={5}
                              value={reviewMsg}
                              onChange={(e: any) => {
                                setReviewMsg(e.target.value);
                                setReviewError(false);
                              }}
                            ></textarea>
                            {reviewError && (
                              <div className="error-msg">
                                {Constants.VALIDATIONS.REVIEW_REQUIRED}
                              </div>
                            )}
                            <button
                              className="btn btn--primary btn--primary-md mt-4"
                              onClick={() => sendReview()}
                            >
                              SUBMIT
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
            {relatedProducts.length > 0 && (
              <>
                <div className="row mb-2 mt-4">
                  <div className="col-md-6">
                    <h4 className="block-title block-title-xl text--dark">
                      Related Products
                    </h4>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end">
                    {totalCount > Constants.RELATED_PRODUCTS_PAGESIZE && (
                      <Link
                        to={{
                          pathname: `/${productInfo?.category?.id}/products`,
                        }}
                        className="text--primary cmn-txt font-weight-medium"
                      >
                        View All
                      </Link>
                    )}{" "}
                  </div>
                </div>
                <div className="row">
                  {relatedProducts.map(
                    (product: {
                      id: number;
                      title: string;
                      price: number;
                      images: [{ url: string }];
                    }) => {
                      return (
                        <div className="col-md-3 mb-4" key={product.id}>
                          <div className="card custom-card product-card border-0 product-hover-card">
                            <div className="card-body">
                              <LoadImage
                                src={product?.images?.[0]?.url}
                                inlineClass="img-fluid img-cursor"
                                onClick={() =>
                                  navigate(`/product/${getProductUrl(product?.id,product.title)}`)
                                }
                              />
                            </div>
                            <div className="card-footer p-0 pt-2 text-start">
                              <Link
                                to={{
                                  pathname: `/product/${getProductUrl(product?.id,product.title)}`,
                                }}
                                className="product--price"
                              >
                                {product?.title}
                              </Link>
                              {product?.price > 0 && (
                                <p className="product--price">
                                  ${product?.price}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetail;
