/* eslint-disable react-hooks/exhaustive-deps */
import { Col, DatePicker, Form, Row } from "antd";
import { Field, Formik, FormikValues } from "formik";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  cardLogoMapping,
  getErrorMessage,
  NotificationWithIcon,
} from "../../Utils/helper";
import * as Constants from "../../Utils/constants";
import { useSelector } from "react-redux";
import CartService from "../../API/Cart";
import { RangePickerProps } from "antd/es/date-picker";
import thrash from "../../assets/images/deletegrey.png";
import { cardValidation } from "../../Utils/validations";

interface Props {
  setLoader: any;
  title: string;
  fromCart: boolean;
  handleCard?: any;
}

const Cards: FC<Props> = ({
  setLoader,
  title,
  fromCart,
  handleCard,
}: Props) => {
  const [cards, setCards] = useState([]);
  const [show, setShow] = useState(false);
  const [haveData, setHaveData] = useState(false);
  const authState = useSelector((state: any) => state.auth);
  const { userInfo } = authState;
  const [selectedCard, setSelectedCard] = useState<any>(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleChangeCardNumber = (value: string, handleChange: any) => {
    const cardNumberString = value
      .replace(new RegExp(Constants.STRING_REGEX), "")
      .replace(new RegExp(Constants.NUMERIC_REGEX), "");
    const matches = cardNumberString.match(
      new RegExp(Constants.CARD_NUMBER_REGEX)
    );
    const match = (matches && matches[0]) || "";
    const parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      handleChange("cardNumber", parts.join(" "));
    } else {
      handleChange("cardNumber", cardNumberString);
    }
  };

  const handleChangeCvv = (value: string, handleChange: any) => {
    if (value.length > Constants.CVV_LENGTH) {
      return;
    }
    const v = value
      .replace(new RegExp(Constants.STRING_REGEX), "")
      .replace(new RegExp(Constants.NUMERIC_REGEX), "")
      .replace(new RegExp(Constants.STRING_NUMERIC_REGEX), "");
    handleChange("cvv", v);
  };

  const getPaymentCards = async () => {
    setLoader(true);
    await CartService.getCards(userInfo.id)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setCards(res?.data);
          setHaveData(true);
        }
      })
      .catch((error: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          error?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const onSaveCardDetails = async (values: FormikValues, resetForm: any) => {
    const data = {
      user: userInfo.id,
      cardHolderName: values.cardHolderName,
      cardNumber: values.cardNumber.replaceAll(" ", ""),
      month: values.expiry ? values.expiry.split("/")[0] : "",
      year: values.expiry
        ? moment(values.expiry.split("/")[1], "YY").format("YYYY")
        : "",
      cvv: values.cvv,
    };
    setLoader(true);
    await CartService.addCardDetails(data)
      .then(async (res: any) => {
        if (res && res?.status === "Success") {
          resetForm();
          handleClose();
          await getPaymentCards();
          NotificationWithIcon("success", res?.message);
        }
      })
      .catch((er: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          er?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const onRemoveCard = async (id: number) => {
    setLoader(true);
    await CartService.removeCard(id)
      .then(async (res: any) => {
        if (res && res?.status === "Success") {
          await getPaymentCards();
          NotificationWithIcon("success", res?.message);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    async function getCards() {
      await getPaymentCards();
    }
    getCards();
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current: any) => {
    // Can not select days before today and today
    return current && current < moment().startOf("month");
  };

  const onSelectCard = (id: number) => {
    if (fromCart) {
      setSelectedCard(id);
      handleCard(id);
    }
  };

  return (
    <>
      <Row>
        <Col xs={24}>
          <div className="d-flex align-items-center">
            <h2 className="block-title block-title-xl-22 mb-0 me-auto">
              {title}
            </h2>
            {cards?.length > 0 && (
              <button
                className="btn btn-borderd--primary bnt-sm d-flex align-items-center py-2"
                onClick={handleShow}
              >
                Add Card
              </button>
            )}
          </div>
          <div className="hr--divider mb-3 mt-2"></div>
        </Col>
      </Row>
      {haveData && cards?.length > 0 && (
        <Row gutter={16}>
          {cards.map((card: any) => {
            return (
              <Col xs={8} key={card.id}>
                <Card
                  className={`custom--card payment--card h-100 mb-0 ${
                    fromCart && selectedCard === card?.id
                      ? "selected--card"
                      : ""
                  }`}
                  onClick={() => onSelectCard(card?.id)}
                >
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <img
                        src={
                          cardLogoMapping.find(
                            (item: any) => item.cardType === card?.cardType
                          )?.imagePath
                        }
                        alt=""
                        className="me-auto card-logo-img"
                      />
                      <ul className="list-unstyled d-flex mb-0 algin-items-center">
                        <li>
                          <Link to="#" onClick={() => onRemoveCard(card.id)}>
                            <img src={thrash} alt="" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <p className="block-title block-title-20 mt-4">
                      XXXX-XXXX-XXXX-{card?.cardNumber}
                    </p>
                    <div className="bottom--details d-flex">
                      <div className="w-75">
                        <label>Card Holder</label>
                        <p className="notranslate">{card?.cardHolderName}</p>
                      </div>
                      <div className="w-25">
                        <label>Expires</label>
                        <p>{`${moment(card?.exp_month, "M").format(
                          "MM"
                        )}/${moment(card?.exp_year, "YYYY").format("YY")}`}</p>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      )}
      {haveData && cards?.length === 0 && (
        <Row gutter={16}>
          <Col
            
            className="col-lg-12 mb-5 col-12 text-center d-flex align-items-center flex-column justify-content-center"
          >
            <div className="mt-4 no-address--info">
              <h4 className="no-address-txt">No Payment Methods Yet!</h4>
              <p className="mb-3">There are no any payment methods added yet</p>

              <button
                className="btn btn-borderd--primary py-2"
                onClick={() => handleShow()}
              >
                Add Payment Methods
              </button>
            </div>
          </Col>
        </Row>
      )}

      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal address--modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Payment Methods</Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-payment">
          <Formik
            initialValues={{
              cardHolderName: "",
              cardNumber: "",
              expiry: "",
              cvv: "",
            }}
            onSubmit={(values: FormikValues, { resetForm }) => {
              onSaveCardDetails(values, resetForm);
            }}
            validationSchema={cardValidation}
          >
            {({ setFieldValue, handleSubmit }) => (
              <Form layout="vertical" onSubmitCapture={handleSubmit}>
                <Row gutter={16}>
                  <Col xl={24}>
                    <Form.Item
                      label="Card Holder Name*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="cardHolderName"
                        name="cardHolderName"
                        placeholder="Enter card holder name"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("cardHolderName")}
                    </Form.Item>
                  </Col>
                  <Col xl={24}>
                    <Form.Item
                      label="Card Number*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="cardNumber"
                        name="cardNumber"
                        placeholder="Enter card number"
                        className="form-control custom-form-control"
                        onChange={(e: any) => {
                          handleChangeCardNumber(e.target.value, setFieldValue);
                        }}
                      />
                      {getErrorMessage("cardNumber")}
                    </Form.Item>
                  </Col>
                  <Col xl={12}>
                    <Form.Item
                      label="Expiry Month*"
                      className="custom-field mb-3"
                    >
                      <Field id="expiry" name="expiry">
                        {() => (
                          <DatePicker
                            name="expiry"
                            className="form-control custom-form-control date-picker"
                            format="MM/YY"
                            picker="month"
                            placeholder="MM/YY"
                            popupClassName="ant-date-picker-custom"
                            onChange={(_e, e2) => {
                              setFieldValue("expiry", e2);
                            }}
                            disabledDate={disabledDate}
                          />
                        )}
                      </Field>
                      {getErrorMessage("expiry")}
                    </Form.Item>
                  </Col>
                  <Col xl={12}>
                    <Form.Item
                      label="Card Code (CVV)*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="cvv"
                        name="cvv"
                        placeholder="CVV"
                        className="form-control custom-form-control"
                        onChange={(e: any) => {
                          handleChangeCvv(e.target.value, setFieldValue);
                        }}
                        type="password"
                      />
                      {getErrorMessage("cvv")}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col xl={24}>
                    <Button
                      type="submit"
                      className="btn--primary btn w-100 btn--primary--200"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Cards;
