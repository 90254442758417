import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import ROUTES from "./routes";
import ChangePassword from "./Components/ChangePassword";
import ProductList from "./Components/ProductList";
import CategoryList from "./Components/CategoryList";
import ProductDetails from "./Components/ProductDetails";
import Home from "./Components/Home";
import MyAccount from "./Components/MyAccount";
import Cart from "./Components/Cart";
import MyOrders from "./Components/MyOrders";
import ManageAddress from "./Components/ManageAddress";
import PaymentMethod from "./Components/PaymentMethod";
import News from "./Components/News";
import ContactUs from "./Components/ContactUs";
import "./assets/css/style.scss";
import "./App.css";
import { getUserToken } from "./Utils/helper";
import MainRouter from "./MainRouter";
import NotFound from "./Components/404NotFound";
import ServiceManuals from "./Components/ServiceManuals";
import ServiceManualDetail from "./Components/ServiceManualDetail";
import FAQs from "./Components/FAQs";
import Tutorials from "./Components/Tutorials";
import RequestQuote from "./Components/RequestQuote";
import Articles from "./Components/Articles";
import ArticleDetails from "./Components/ArticleDetails";
import PartNumberRefs from "./Components/PartNumberRefs";
import ReadOnlyProductDetails from "./Components/ReadOnlyProductDetails";
import ReadOnlyCategory from "./Components/ReadOnlyCategory";
import Layout from "./Components/Layout";
import Cookie from "js-cookie";
import * as Constants from "./Utils/constants";
import { store } from "./Redux/store";
import AuthActions from "./Redux/action/auth";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Resource from "./Components/Resources";
import Aboutus from "./Components/AboutUs";

const App = () => {
  const [loginRoutes] = useState([
    ROUTES.MYACCOUNT,
    ROUTES.CHANGEPASSWORD,
    ROUTES.MANAGEADDRESS,
    ROUTES.MYORDERS,
    ROUTES.PAYMENTMETHOD,
  ]);
  const accessToken = getUserToken();

  if (!accessToken) {
    localStorage.removeItem("lastInteractionTime");
    Cookie.remove(Constants.AUTH_TOKEN);
    store.dispatch(AuthActions.Reset());
  }

  useEffect(() => {
    const defaultLang: any = process.env.REACT_APP_DEFAULT_LANGUAGE;
    Cookie.set("googtrans", defaultLang);
  }, []);

  return (
    <div className="main-container">
      <>
        {accessToken !== "" && accessToken !== undefined ? (
          <Layout>
            <Routes>
              <Route path={ROUTES.HOME} element={<Home />} />
              <Route
                path={ROUTES.CHANGEPASSWORD}
                element={<ChangePassword />}
              />
              <Route path={ROUTES.PRODUCTS} element={<ProductList />} />
              <Route
                path={ROUTES.PRODUCT_CATEGORY}
                element={<CategoryList />}
              />
              <Route
                path={ROUTES.PRODUCT_DETAILS}
                element={<ProductDetails />}
              />
              <Route path={ROUTES.MYACCOUNT} element={<MyAccount />} />
              <Route path={ROUTES.MYCART} element={<Cart />} />
              <Route path={ROUTES.MYORDERS} element={<MyOrders />} />
              <Route path={ROUTES.MANAGEADDRESS} element={<ManageAddress />} />
              <Route path={ROUTES.PAYMENTMETHOD} element={<PaymentMethod />} />
              <Route path={ROUTES.NEWS} element={<News />} />
              <Route path={ROUTES.CONTACT} element={<ContactUs />} />
              <Route
                path={ROUTES.SERVICE_MANUAL}
                element={<ServiceManuals />}
              />
              <Route path={ROUTES.TUTORIALS} element={<Tutorials />} />
              <Route
                path={ROUTES.MANUAL_DETAILS}
                element={<ServiceManualDetail />}
              />
              <Route path={ROUTES.FAQ} element={<FAQs />} />
              <Route path={ROUTES.REQUEST_QUOTE} element={<RequestQuote />} />
              <Route path={ROUTES.ARTICLES} element={<Articles />} />
              <Route
                path={ROUTES.ARTICLESDETAILS}
                element={<ArticleDetails />}
              />
              <Route path={ROUTES.PARTNUMBER} element={<PartNumberRefs />} />
              <Route
                path={ROUTES.READONLYCATEGORY}
                element={<ReadOnlyCategory />}
              />
              <Route
                path={ROUTES.MAINPRODUCTDETAILS}
                element={<ReadOnlyProductDetails />}
              />
              <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
              <Route path={ROUTES.RESOURCES} element={<Resource/>} />
              <Route path={ROUTES.ABOUT_US} element={<Aboutus/>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout>
        ) : (
          <MainRouter loginRoutes={loginRoutes} />
        )}
      </>
    </div>
  );
};

export default App;
