/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import CommonService from "../../API/Common";
import ProductService from "../../API/Product";
import rightarrow from "../../assets/images/left-arrow-orange.svg";
import servicemanual from "../../assets/images/right-img.svg";
import truckimg from "../../assets/images/truckimg.jpeg";
import Loader from "../../Common/Loader";
import LoadImage from "../../Common/LoadImage";
import ROUTES from "../../routes";
import { VALIDATIONS } from "../../Utils/constants";
import { convertToUrlFormat, getProductUrl, geturl, NotificationWithIcon } from "../../Utils/helper";
import { useLocation } from 'react-router-dom';

const Home = () => {
  const [loader, setLoader] = useState<any>(false);
  const [categories, setCategories] = useState<any>([]);
  const [sliderImg, setSliderImgs] = useState<any>([]);
  const [productCategory, setProductCategory] = useState<any>([]);
  const [shouldScroll, setShouldScroll] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const getError = (err: any) => {
    NotificationWithIcon(
      "error",
      err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
    );
    setLoader(false);
  };

  useEffect(() => {
    if (location.state?.scrollToSection) {
      setShouldScroll(true);
    }
  }, [location]);

  useEffect(() => {
    if (shouldScroll) {
      const element = document.getElementById(location.state.scrollToSection);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
      setShouldScroll(false);
    }
  }, [shouldScroll, location.state]);


  const getHomeSliders = async () => {
    setLoader(true);
    await CommonService.getHomeSliders()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setSliderImgs(res?.data?.images);
        }
      })
      .catch((err: any) => {
        getError(err);
      });
  };

  const getHomeCategoryList = async () => {
    setLoader(true);
    await ProductService.getCategoryList()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setCategories(res?.data);
        }
      })
      .catch((err: any) => {
        getError(err);
      });
  };

  const getProductCategoryList = async () => {
    setLoader(true);
    await ProductService.getAllReadOnlyProductCategories(
      "all=true&populate[images][fields]=url"
    )
      .then((res: any) => {
        setLoader(false);
        if (res && res?.status === "Success") {
          setProductCategory(res?.data);
        }
      })
      .catch((err: any) => {
        getError(err);
      });
  };

  const getCountryList = async () => {
    await CommonService.getCountry()
      .then((res: any) => {
        if (res && res?.data) {
          localStorage.setItem("countryList", JSON.stringify(res?.data));
        }
      })
      .catch((err: any) => {
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getHomePageInfo() {
      await getHomeSliders();
      await getHomeCategoryList();
      await getProductCategoryList();
      await getCountryList();
    }
    getHomePageInfo();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <div>
        <Carousel className="banner-slider">
          {sliderImg?.length > 0 &&
            sliderImg.map((sliderObj: any) => {
              return (
                <Carousel.Item key={sliderObj?.id}>
                  <img
                    className="d-block w-100"
                    src={sliderObj?.url ? geturl(sliderObj?.url) : truckimg}
                    alt="First slide"
                  />
                </Carousel.Item>
              );
            })}
        </Carousel>
      </div>
      <div className="about-block">
        <div className="container">
          <div className="row">
            <div className="col-md-4 ps-5">
              <p className="block-title-xxl text-dark">WELCOME TO SKYAZUL</p>
            </div>
            <div className="col-md-8">
              <div className="max-width-648">
                <p className="cmn-txt cmn-txt-md mb-3">
                  SkyAzúl Equipment Solutions is a proven leader in the sales
                  and service of monitoring systems for construction and mining
                  equipment. We offer a full range of information and monitoring
                  systems for heavy equipment, from the most basic Anti-Two
                  Block System for mobile cranes to the most complex Load Moment
                  Indicators. Our Powerline Proximity Warning Systems for
                  Construction Equipment and Aerial Platforms are the most
                  user-friendly and accurate in the industry. We provide
                  comprehensive technical support, including field service.
                </p>
                <button
                  className="btn btn-borderd--primary"
                  onClick={() => navigate(ROUTES.ABOUT_US)}
                >
                  Learn More <img src={rightarrow} alt="" className="ms-2" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {productCategory?.length > 0 && (
         <div id="product-section" className="product-categories-block">
          <div className="container">
            <div className="row">
              <div className="col-md-12 mb-3 text-center">
                <p className="block-title block-title-md text--primary mb-0">
                  SkyAzul
                </p>
                <h4 className="block-title-xxl text-white">
                  Product Categories
                </h4>
              </div>
            </div>
            <div className="row">
              {productCategory.map((catObj: any) => {
                return (
                  <div className="col-md-4 mb-4" key={catObj?.id}>
                    <div className="product-img-box">
                      <LoadImage
                        src={catObj?.images?.[0]?.url}
                        inlineClass="img-fluid img-cursor"
                        onClick={() =>
                          navigate(`/readonly-categories/${catObj.id}/${convertToUrlFormat(catObj.name)}`, {
                            state: { category: catObj },
                          })
                        }
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
      <div className="service-manual-documentation-training-block-new">
        <div
          className="container service-manual-block"
          style={{ cursor: "pointer" }}
          onClick={() => navigate(ROUTES.SERVICE_MANUAL)}
        >
          <div className="row">
            <div className="col-lg-6 align-self-center custom-one">
              <div className="content-block">
                <h4 className="block-title-new block-title-xxxl text-white">
                  Service Manuals Documentation & Training
                </h4>
              </div>
            </div>
            <div className="col-lg-6 custom-two">
              <div className="image-inner">
                <img src={servicemanual} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="online-store-block">
        <div className="container position-relative">
          <div className="row mb-4">
            <div className="col-md-12 text-center">
              <p className="block-title block-title-md text--primary mb-0">
                SkyAzul
              </p>
              <h4 className="block-title-xxl text-dark">Online Store</h4>
            </div>
          </div>
          <div className="row">
            
            {categories?.length > 0 &&
              categories.map((obj: any) => {
                return (
                  <div className="col-md-4 mb-4 d-flex" key={obj?.id}>
                    <div className="card custom-card product-card">
                      <div className="card-body">
                        <LoadImage
                          src={obj?.images?.url}
                          inlineClass="img-fluid img-cursor"
                          onClick={() => navigate(`/product-category/${getProductUrl(obj?.id, obj?.title)}`)}
                        />
                      </div>
                      <div className="card-footer">
                        <Link
                          to={{
                            pathname: `/product-category/${getProductUrl(obj?.id, obj?.title)}`,

                          }}
                        >
                          {obj?.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

