import { AxiosResponse } from "axios";
import Service from "../../Lib/service";
import { getUserToken } from "../../Utils/helper";

const authorizationKey: any = process.env.REACT_APP_AUTHORIZATION_KEY;
const AuthService = {
  postLoginDetail: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        {
          url: `auth/local`,
          data,
        },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("loginDetail error:",error);
      throw error;
    }
  },
  postRegister: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/local/register`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("register error:",error);
      throw error;
    }
  },
  forgotPassword: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/forgot-password`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("forgotpassword error:",error);
      throw error;
    }
  },
  resetPassword: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/reset-password`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("resetPassword error:",error);
      throw error;
    }
  },
  changePassword: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/change-password`, data },
        { authorization: `Bearer ${getUserToken()}` }
      );
    } catch (error) {
      console.log("changepassword error:",error);
      throw error;
    }
  },
  updateProfile: async (
    userId: number,
    data: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.update(
        { url: `users/${userId}`, data },
        { authorization: `Bearer ${getUserToken()}` }
      );
    } catch (error) {
      console.log("updateProfile error:",error);
      throw error;
    }
  },
  resetLinkVerification: async (
    data: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/verify-Reset-Password-Token`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("resetLinkVerification error:",error);
      throw error;
    }
  },
  getUserByEmail: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `auth/getUserByEmailId`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("getUserByEmail error:",error);   
      throw error;
    }
  },
};

export default AuthService;
