import { Nav, Tab } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ROUTES from "../../routes";
import { handleLogout } from "../../Utils/helper";
import Breadcrumb from "../Breadcrumb";
import { useEffect } from "react";

const SidebarLayout = ({ children, activeTab }: any) => {
  const navigate = useNavigate();

  const handleRouteChange = (key: string, event: any, ) => {
    event.preventDefault();
    switch (key) {
      case "myAccount":
        navigate(ROUTES.MYACCOUNT);
        break;
      case "myOrders":
        navigate(ROUTES.MYORDERS);
        break;
      case "manageAddress":
        navigate(ROUTES.MANAGEADDRESS);
        break;
      case "payment":
        navigate(ROUTES.PAYMENTMETHOD);
        break;
      case "changePassword":
        navigate(ROUTES.CHANGEPASSWORD);
        break;
      case "logout":
        handleLogout();
        navigate(ROUTES.HOME);
        break;
      default:
        break;
    }
  };

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "My Account",
      path: "#",
    },
  ];

  useEffect(() => {
    const activeNavLink = document.querySelector('.nav-link.active');
    if (activeNavLink) {
      activeNavLink.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, [activeTab]);

  return (
    <>
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="My Account" />
      <div className="settings-block">
        <div className="container">
          <div className="vertical-tab">
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab || "myAccount"}
              onSelect={(key: any, event: any) => handleRouteChange(key, event)}
            >
              <Nav
                variant="pills"
                className="flex-column"
                activeKey={activeTab || "myAccount"}
              >
                <Nav.Item>
                  <Nav.Link eventKey="myAccount">My Account</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="myOrders">My Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="manageAddress">Manage Address</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="payment">Payment Methods</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="changePassword">Change Password</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="logout" className="text--warning">
                    Logout
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey={activeTab}>{children}</Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidebarLayout;
