// #region Local Imports
import ActionTypes from "../../actionTypes";

const AuthActions = {
  Map: (payload: {}) => ({
    payload,
    type: ActionTypes.Auth.SetReducer,
  }),

  Reset: () => ({
    type: ActionTypes.Auth.ResetReducer,
  }),

  setUserInfo: (payload: {}) => ({
    payload,
    type: ActionTypes.Auth.SetUserInformation,
  }),

  setIsSocialLogin: (payload: {}) => ({
    payload,
    type: ActionTypes.Auth.SetIsSocialLogin,
  }),
};

export default AuthActions;
