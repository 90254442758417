/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Form, Select } from "antd";
import { useState, useEffect } from "react";
import { Field, Formik, FormikValues } from "formik";
import { Button, Modal } from "react-bootstrap";
import SidebarLayout from "../../Common/SideBarLayout";
import {
  ADDRESS_TYPE,
  CONTACT_LENGTH,
  DEFAULT_COUNTRY,
  MAX_STREET_ADDRESS_LENGTH,
  VALIDATIONS,
} from "../../Utils/constants";
import {
  filterData,
  getCountries,
  getErrorMessage,
  NotificationWithIcon,
  validateContact,
  validateZipcode,
} from "../../Utils/helper";
import AuthService from "../../API/Auth";
import { useDispatch, useSelector } from "react-redux";
import AuthActions from "../../Redux/action/auth";
import Loader from "../../Common/Loader";
import CommonService from "../../API/Common";
import { manageAddressValidation } from "../../Utils/validations";

const ManageAddress = () => {
  const [show, setShow] = useState(false);
  const [addressType, setAddressType] = useState<string>("");
  const [isEditBilling, setIsEditBilling] = useState<boolean>(false);
  const [isEditShipping, setIsEditShipping] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [countriesData, setCountriesData] = useState<any>([]);
  const [statesData, setStatesData] = useState<any>([]);
  const [cityData, setCityData] = useState<any>([]);
  const [selectedState, setSelectedState] = useState<any>("");
  const [cityTotal, setCityTotal] = useState<any>(null);
  const [page, setPage] = useState<any>(1);
  const authState = useSelector((state: any) => state.auth);
  const { userInfo } = authState;
  const dispatch = useDispatch();
  const defaultCountry: any = DEFAULT_COUNTRY;

  const handleClose = () => setShow(false);

  const handleShow = (type: string) => {
    setAddressType(type);
    setShow(true);
    if (isEditBilling || isEditShipping) {
      setCityData(
        type === ADDRESS_TYPE.BILLING
          ? [userInfo?.billingCity]
          : [userInfo?.shippingCity]
      );
    }
  };

  const checkAddress = (
    billingFirstName: string,
    shippingStreetAddress: string
  ) => {
    if (billingFirstName) {
      setIsEditBilling(true);
    }
    if (shippingStreetAddress) {
      setIsEditShipping(true);
    }
  };

  const onSaveAddress = async (values: FormikValues) => {
    const billingReq = {
      billingFirstName: values.firstname.trim(),
      billingLastName: values.lastname.trim(),
      billingEmail: values.email,
      billingContactNumber: values.contact,
      billingStreetAddress: values.streetAddress.trim(),
      billingCountry: values.country,
      billingState: values.state,
      billingCity: values.city,
      billingZipCode: values.zipCode,
    };
    const shippingReq = {
      shippingFirstName: values.firstname.trim(),
      shippingLastName: values.lastname.trim(),
      shippingEmail: values.email,
      shippingContactNumber: values.contact,
      shippingStreetAddress: values.streetAddress.trim(),
      shippingCountry: values.country,
      shippingState: values.state,
      shippingCity: values.city,
      shippingZipCode: values.zipCode,
    };
    const reqObj =
      addressType === ADDRESS_TYPE.BILLING ? billingReq : shippingReq;
    setLoader(true);
    await AuthService.updateProfile(userInfo?.id, reqObj)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", VALIDATIONS.PROFILE_UPDATE);
          dispatch(
            AuthActions.setUserInfo({
              userInfo: res?.data?.user,
            })
          );
          handleClose();
          checkAddress(
            res?.data?.user?.billingFirstName,
            res?.data?.user?.shippingFirstName
          );
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  const getCountry = async () => {
    await getCountries(setCountriesData);
  };

  const url = "all=true&sort=id:asc";

  const getStates = async (countryId: string) => {
    await CommonService.getState(countryId, url)
      .then((res: any) => {
        if (res && res?.data) {
          setStatesData(res?.data);
        }
      })
      .catch(() => {
        setStatesData([]);
      });
  };

  const getCity = async (
    pageNumber: number,
    stateId: string,
    search?: string
  ) => {
    let cityUrl = `pagination[page]=${pageNumber}&pagination[pageSize]=50&sort=id:asc`;
    if (search) {
      cityUrl += `&search=${search}`;
    }
    await CommonService.getCity(stateId, cityUrl)
      .then((res: any) => {
        let arr: any = [];
        if (search || pageNumber === 1) {
          setCityData([]);
          arr = [...res?.data];
        } else {
          arr = [...cityData, ...res?.data];
        }
        setCityData(arr);
        if (!cityTotal) {
          setCityTotal(res?.meta?.total);
        }
      })
      .catch(() => {
        setCityData([]);
      });
  };

  const onCountrySelect = (country: string) => {
    getStates(country);
  };

  const onStateSelect = (state: string) => {
    setSelectedState(state);
    setPage(1);
    getCity(1, state);
  };

  useEffect(() => {
    checkAddress(userInfo?.billingFirstName, userInfo?.shippingStreetAddress);
    getCountry();
    getStates(userInfo?.shippingCountry?.id || defaultCountry.id);
  }, []);

  const getTitle = (isEdit: boolean) => {
    return isEdit ? "Edit Address" : "Add Address";
  };

  const checkTitle = () => {
    if (addressType === ADDRESS_TYPE.BILLING) {
      return getTitle(isEditBilling);
    } else {
      return getTitle(isEditShipping);
    }
  };

  const getData = (billing: string, shipping: string) => {
    if (addressType === ADDRESS_TYPE.BILLING) {
      return isEditBilling ? billing : "";
    } else {
      return isEditShipping ? shipping : "";
    }
  };

  const onSearchCity = (searchValue: string, setFieldValue: any) => {
    setPage(1);
    setFieldValue("city", searchValue);
    if (searchValue !== "") {
      getCity(1, selectedState, searchValue);
    } else {
      getCity(1, selectedState);
    }
  };

  const onCityScroll = async (e: any) => {
    if (cityTotal === cityData?.length) {
      return;
    }
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setPage((page: any) => page + 1);
      await getCity(page + 1, selectedState);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <SidebarLayout activeTab="manageAddress">
        <Row>
          <Col xs={24}>
            <h2 className="block-title block-title-xl-22 mb-3">
              Manage Address
            </h2>
            <div className="hr--divider mb-3"></div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col
            
            className="col-lg-6 col-12 text-center d-flex align-items-center flex-column justify-content-center "
          >
            <h2 className="block-title block-title-20 mb-4 font-weight-semibold">
              Billing Address
            </h2>
            <div className="mb-4 no-address--info">
              {!isEditBilling ? (
                <>
                  <h4 className="no-address-txt">No Address Yet!</h4>
                  <p className="mb-3">
                    Please add your address for your better experience
                  </p>
                </>
              ) : (
                <>
                  <h4>{`${userInfo?.billingFirstName} ${userInfo?.billingLastName}`}</h4>
                  <p>
                    {`${userInfo?.billingStreetAddress}, ${userInfo?.billingCity?.name}, ${userInfo?.billingZipCode}`}
                    ,
                  </p>
                  <p>
                    <span className="text--grey">Phone No.:</span>{" "}
                    {userInfo?.billingContactNumber}{" "}
                  </p>
                  <p className="mb-3">
                    <span className="text--grey">Email:</span>{" "}
                    {userInfo?.billingEmail}
                  </p>
                </>
              )}
              <button
                className="btn btn-borderd--primary py-2"
                onClick={() => handleShow(ADDRESS_TYPE.BILLING)}
              >
                {isEditBilling ? "Edit Address" : "Add Address"}
              </button>
            </div>
          </Col>
          <Col
            
            className="col-lg-6 col-12 text-center d-flex align-items-center flex-column justify-content-center "
          >
            <h2 className="block-title block-title-20 font-weight-semibold mb-4">
              Shipping Address
            </h2>
            <div className="mb-4 no-address--info">
              {!isEditShipping ? (
                <>
                  <h4 className="no-address-txt">No Address Yet!</h4>
                  <p className="mb-3">
                    Please add your address for your better experience
                  </p>
                </>
              ) : (
                <>
                  <h4>
                    {userInfo?.shippingFirstName && userInfo?.shippingLastName
                      ? `${userInfo?.shippingFirstName} ${userInfo?.shippingLastName}`
                      : ""}
                  </h4>
                  <p>
                    {`${userInfo?.shippingStreetAddress}, ${userInfo?.shippingCity?.name}, ${userInfo?.shippingZipCode}`}
                    ,
                  </p>
                  <p>
                    <span className="text--grey">Phone No.:</span>{" "}
                    {userInfo?.shippingContactNumber || "-"}{" "}
                  </p>
                  <p className="mb-3">
                    <span className="text--grey">Email:</span>{" "}
                    {userInfo?.shippingEmail || "-"}
                  </p>
                </>
              )}
              <button
                className="btn btn-borderd--primary py-2"
                onClick={() => handleShow(ADDRESS_TYPE.SHIPPING)}
              >
                {isEditShipping ? "Edit Address" : "Add Address"}
              </button>
            </div>
          </Col>
        </Row>
      </SidebarLayout>
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal custom-modal-md address--modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{checkTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="add-address">
          <Formik
            initialValues={{
              firstname: getData(
                userInfo?.billingFirstName,
                userInfo?.shippingFirstName
              ),
              lastname: getData(
                userInfo?.billingLastName,
                userInfo?.shippingLastName
              ),
              email: getData(userInfo?.billingEmail, userInfo?.shippingEmail),
              contact: getData(
                userInfo?.billingContactNumber,
                userInfo?.shippingContactNumber
              ),
              streetAddress: getData(
                userInfo?.billingStreetAddress,
                userInfo?.shippingStreetAddress
              ),
              city: getData(
                userInfo?.billingCity?.id,
                userInfo?.shippingCity?.id
              ),
              state: getData(
                userInfo?.billingState?.id,
                userInfo?.shippingState?.id
              ),
              country:
                getData(
                  userInfo?.billingCountry?.id,
                  userInfo?.shippingCountry?.id
                ) || defaultCountry.id,
              zipCode: getData(
                userInfo?.billingZipCode,
                userInfo?.shippingZipCode
              ),
            }}
            onSubmit={(values: FormikValues) => {
              onSaveAddress(values);
            }}
            validationSchema={manageAddressValidation}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form layout="vertical" onSubmitCapture={handleSubmit}>
                <Row gutter={16}>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item
                      label="First name*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="firstname"
                        name="firstname"
                        placeholder="Enter your first name"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("firstname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item label="Last name*" className="custom-field mb-3">
                      <Field
                        id="lastname"
                        name="lastname"
                        placeholder="Enter your last name"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("lastname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item
                      label="Email address*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="email"
                        name="email"
                        placeholder="Enter email address"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("email")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item
                      label="Contact number*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="contact"
                        name="contact"
                        validate={validateContact}
                        render={(renderProps: any) => (
                          <input
                            {...renderProps.field}
                            type="text"
                            placeholder="Enter contact number"
                            maxLength={CONTACT_LENGTH}
                            className="form-control custom-form-control"
                          />
                        )}
                      />{" "}
                      {getErrorMessage("contact")}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="col-lg-12 col-md-12 col-12">
                    <h4 className="block-title block-title-md font-weight-semibold">
                      {addressType === ADDRESS_TYPE.BILLING
                        ? "Billing Address"
                        : "Shipping Address"}
                    </h4>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="col-lg-12 col-md-12 col-12">
                    <Form.Item
                      label="Street address*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="streetAddress"
                        name="streetAddress"
                        placeholder="House number and street name"
                        className="form-control custom-form-control"
                        maxLength={MAX_STREET_ADDRESS_LENGTH}
                      />
                      {getErrorMessage("streetAddress")}
                    </Form.Item>
                  </Col>
                
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item
                      label="Town / City*"
                      className="custom-field mb-3"
                    >
                      <Select
                        popupClassName="custom--select"
                        placeholder="Select town / city"
                        showSearch
                        options={cityData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        onChange={(e) => {
                          setFieldValue("city", e);
                        }}
                        value={values.city === "" ? undefined : values.city}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        onSearch={(e: any) => onSearchCity(e, setFieldValue)}
                        onPopupScroll={onCityScroll}
                      />
                      {getErrorMessage("city")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item label="State*" className="custom-field mb-3">
                      <Select
                        popupClassName="custom--select"
                        placeholder="Select state"
                        showSearch
                        options={statesData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        onChange={(e) => {
                          onStateSelect(e);
                          setFieldValue("state", e);
                          setFieldValue("city", "");
                        }}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        value={values.state === "" ? undefined : values.state}
                      />
                      {getErrorMessage("state")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item
                      label="Country / Region *"
                      className="custom-field mb-3"
                    >
                      <Select
                        popupClassName="custom--select"
                        placeholder="Select country / region"
                        showSearch
                        options={countriesData?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        onChange={(e) => {
                          setFieldValue("country", e);
                          onCountrySelect(e);
                          setFieldValue("state", "");
                          setFieldValue("city", "");
                          setCityData([]);
                        }}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        value={values.country}
                      />
                      {getErrorMessage("country")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-md-6 col-12">
                    <Form.Item label="ZIP Code*" className="custom-field mb-3">
                      <Field
                        id="zipCode"
                        name="zipCode"
                        placeholder="Enter zip code"
                        className="form-control custom-form-control"
                        validate={validateZipcode}
                      />
                      {getErrorMessage("zipCode")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-12 col-md-12 col-12">
                    <Button
                      type="submit"
                      className="btn--primary btn w-100 btn--primary--200"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ManageAddress;
