/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import ArticleService from "../../API/Articles";
import Loader from "../../Common/Loader";
import { NotificationWithIcon, paginationTemplate } from "../../Utils/helper";
import * as Constants from "../../Utils/constants";
import moment from "moment";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { Paginator } from "primereact/paginator";
import { Link } from "react-router-dom";
import LoadImage from "../../Common/LoadImage";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const Articles: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [articleimg, setArticleimg] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [firstPage, setFirstPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<any>(null);
  const [search, setSearchValue] = useState<any>(null);

  const getArticlesList = async (pageNumber: number, searchValue?: string) => {
    setLoader(true);
    let queryParams = `pagination[page]=${pageNumber}&pagination[pageSize]=${Constants.PAGESIZE}&populate[images][fields]=url`;
    if (searchValue) {
      queryParams += `&keyword=${searchValue}`;
    }
    await ArticleService.getArticlesList(queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          let arr = res?.data.map((item: any) => {
            return {
              id: item?.id,
              images: item?.images?.url,
              date: item?.createdAt,
              title: item?.title,
              description: item?.description,
            };
          });
          setArticleimg(arr);
          setLoader(false);
          setTotalCount(res?.meta?.total);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getArticlesDetails() {
      await getArticlesList(page);
    }
    getArticlesDetails();
  }, []);

  const onPageChange = async (pageObj: any) => {
    setFirstPage(pageObj.first);
    setPage(pageObj.page + 1);
    await getArticlesList(pageObj.page + 1);
  };

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: "#",
    },
    {
      title: "Industry Standard and Articles Details",
      path: "#",
    },
  ];

  const handleOnChange = async (e: any) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      setFirstPage(0);
      setPage(1);
      await getArticlesList(1);
    }
  };

  const handleSearch = async (e: any) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      setFirstPage(0);
      setPage(1);
      await getArticlesList(1, search);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Resources" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12 d-flex">
            <h4 className="block-title-xl text--dark">
              Industry Standard and Articles
            </h4>
            <div className="custom-search ms-auto">
              <Input
                placeholder="Search by articles"
                value={search}
                onChange={(e: any) => handleOnChange(e)}
                prefix={<SearchOutlined />}
                onKeyDown={(e: any) => handleSearch(e)}
              />
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {articleimg.map((dataItem: any) => (
            <>
              <div className="col-md-4 mb-4">
                <div className="card custom-card article--card h-100">
                  <Link
                    to={{
                      pathname: `/articleDetails/${dataItem?.id}`,
                    }}
                  >
                    <div className="card-header p-0">
                      <LoadImage src={dataItem.images} />
                    </div>
                  </Link>
                  <div className="card-body custom-field">
                    <div className="row">
                      <div className="col-12 mb-2">
                        <time>
                          {`${moment(dataItem.date).format(
                            Constants.PRODUCT_REVIEW_DATE_FROMAT
                          )}`}
                        </time>
                      </div>
                      <div className="col-12 mb-2">
                        <label>{dataItem.title}</label>
                      </div>
                      <div className="col-12">
                        <p className="mb-0">{dataItem.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ))}

          <Paginator
            template={paginationTemplate}
            first={firstPage}
            rows={Constants.PAGESIZE}
            totalRecords={totalCount}
            className="custom--paginattion mb-5 justify-content-end"
            onPageChange={onPageChange}
          />
        </div>
      </div>
    </>
  );
};

export default Articles;
