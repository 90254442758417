import * as Yup from "yup";
import * as Constants from "./constants";
import { checkPasswordChar, checkPasswordWithOld } from "./helper";

const loginValidations = Yup.object().shape({
  username: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.USERNAME_EMAIL_REQUIRED),
  password: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.PASSWORD_REQUIRED)
    .test(
      "shouldMatchPolicy",
      Constants.VALIDATIONS.INVALID_PASSWORD,
      (value: any) => checkPasswordChar(value)
    ),
  recaptcha: Yup.string().required(Constants.VALIDATIONS.CAPTCHA_REQUIRED),
});

const registerValidations = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.FIRSTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  lastname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.LASTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  email: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.EMAIL_REQUIRED)
    .matches(Constants.EMAIL_REGEX, Constants.VALIDATIONS.INVALID_EMAIL),
  contact: Yup.string().required(Constants.VALIDATIONS.CONTACT_REQUIRED),
  country: Yup.string().required(Constants.VALIDATIONS.COUNTRY_REQUIRED),
  password: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.PASSWORD_REQUIRED)
    .test(
      "shouldMatchPolicy",
      Constants.VALIDATIONS.INVALID_PASSWORD,
      (value: any) => checkPasswordChar(value)
    ),
  confirmPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.CONFIRM_PASSWORD_REQUIRED)
    .oneOf(
      [Yup.ref("password"), null],
      Constants.VALIDATIONS.PASSWORD_NOT_MATCH
    ),
  recaptcha: Yup.string().required(Constants.VALIDATIONS.CAPTCHA_REQUIRED),
});

const forgotPasswordValidations = Yup.object({
  email: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.EMAIL_REQUIRED)
    .matches(Constants.EMAIL_REGEX, Constants.VALIDATIONS.INVALID_EMAIL),
});

const changePasswordValidations = Yup.object({
  oldPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.OLD_PASSWORD_REQUIRED)
    .test(
      "shouldMatchPolicy",
      Constants.VALIDATIONS.INVALID_PASSWORD,
      (value: any) => checkPasswordChar(value)
    ),
  newPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.NEW_PASSWORD_REQUIRED)
    .test(
      "shouldMatchPolicy",
      Constants.VALIDATIONS.INVALID_PASSWORD,
      (value: any) => checkPasswordChar(value)
    )
    .test(
      "shouldNotMatchPolicy",
      Constants.VALIDATIONS.OLD_NEW_MATCH,
      (value: any, context: any) =>
        !checkPasswordWithOld(value, context?.from[0]?.value?.oldPassword)
    ),
  confirmPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.CONFIRM_PASSWORD_REQUIRED)
    .oneOf(
      [Yup.ref("newPassword"), null],
      Constants.VALIDATIONS.PASSWORD_NOT_MATCH
    ),
});

const resetPasswordValidations = Yup.object({
  newPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.NEW_PASSWORD_REQUIRED)
    .test(
      "shouldMatchPolicy",
      Constants.VALIDATIONS.INVALID_PASSWORD,
      (value: any) => checkPasswordChar(value)
    ),
  confirmPassword: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.CONFIRM_PASSWORD_REQUIRED)
    .oneOf(
      [Yup.ref("newPassword"), null],
      Constants.VALIDATIONS.PASSWORD_NOT_MATCH
    ),
});

const updateProfileValidations = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.FIRSTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  lastname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.LASTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  country: Yup.string().required(Constants.VALIDATIONS.COUNTRY_REQUIRED),
});

const manageAddressValidation = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.FIRSTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  email: Yup.string()
    .required(Constants.VALIDATIONS.EMAIL_REQUIRED)
    .matches(Constants.EMAIL_REGEX, Constants.VALIDATIONS.INVALID_EMAIL),
  lastname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.LASTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  contact: Yup.string().required(Constants.VALIDATIONS.CONTACT_REQUIRED),
  streetAddress: Yup.string().required(
    Constants.VALIDATIONS.STREET_ADDRESS_REQUIRED
  ),
  city: Yup.string().required(Constants.VALIDATIONS.CITY_REQUIRED),
  state: Yup.string().required(Constants.VALIDATIONS.STATE_REQUIRED),
  country: Yup.string().required(Constants.VALIDATIONS.COUNTRY_REQUIRED),
  zipCode: Yup.string().required(Constants.VALIDATIONS.ZIPCODE_REQUIRED),
});

const couponValidation = Yup.object().shape({
  code: Yup.string().trim().required(Constants.VALIDATIONS.COUPON_REQUIRED),
});

const shippingValidation = Yup.object().shape({
  shippingStreetAddress: Yup.string().required(
    Constants.VALIDATIONS.STREET_ADDRESS_REQUIRED
  ),
  shippingCountry: Yup.string().required(
    Constants.VALIDATIONS.COUNTRY_REQUIRED
  ),
  shippingCity: Yup.string().required(Constants.VALIDATIONS.CITY_REQUIRED),
  shippingState: Yup.string().required(Constants.VALIDATIONS.STATE_REQUIRED),
  shippingZipcode: Yup.string().required(
    Constants.VALIDATIONS.ZIPCODE_REQUIRED
  ),
});

const contactValidation = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.NAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  email: Yup.string()
    .required(Constants.VALIDATIONS.EMAIL_REQUIRED)
    .matches(Constants.EMAIL_REGEX, Constants.VALIDATIONS.INVALID_EMAIL),
  subject: Yup.string().required(Constants.VALIDATIONS.SUBJECT_REQUIRED),
  message: Yup.string().required(Constants.VALIDATIONS.MESSAGE_REQUIRED),
});

const cardValidation = Yup.object().shape({
  cardHolderName: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.CARD_HOLDERNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  cardNumber: Yup.string()
    .required(Constants.VALIDATIONS.CARD_NUMBER_REQURIED)
    .test("len", Constants.VALIDATIONS.CARD_NUMBER_LENGTH, (val: any) =>
      Constants.CARD_NUMBERS_LENGTH.includes(val?.toString().length)
    ),
  expiry: Yup.string().required(Constants.VALIDATIONS.EXPIRY_DATE_REQUIRED),
  cvv: Yup.string()
    .required(Constants.VALIDATIONS.CVV_REQUIRED)
    .matches(Constants.CVV_REGEX, Constants.VALIDATIONS.CVV_INVALID),
});

const quoteValidations = Yup.object().shape({
  firstname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.FIRSTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  lastname: Yup.string()
    .trim()
    .required(Constants.VALIDATIONS.LASTNAME_REQUIRED)
    .matches(
      Constants.NAME_VALIDATION_REGEX,
      Constants.VALIDATIONS.INVALID_NAME
    )
    .max(Constants.MAX_NAME_LENGTH, Constants.VALIDATIONS.INVALID_NAME),
  address: Yup.string().trim().required(Constants.VALIDATIONS.ADDRESS_REQUIRED),
  email: Yup.string()
    .required(Constants.VALIDATIONS.EMAIL_REQUIRED)
    .matches(Constants.EMAIL_REGEX, Constants.VALIDATIONS.INVALID_EMAIL),
  phone: Yup.string().required(Constants.VALIDATIONS.CONTACT_REQUIRED),
  company: Yup.string().required(Constants.VALIDATIONS.COMPANY_REQUIRED),
  country: Yup.string().required(Constants.VALIDATIONS.COUNTRY_REQUIRED),
  city: Yup.string().required(Constants.VALIDATIONS.CITY_REQUIRED),
  state: Yup.string().required(Constants.VALIDATIONS.STATE_REQUIRED),
  zipcode: Yup.string().required(Constants.VALIDATIONS.ZIPCODE_REQUIRED),
  fax: Yup.string()
    .required(Constants.VALIDATIONS.FAX_REQUIRED)
    .matches(Constants.FAX_REGEX, Constants.VALIDATIONS.FAX_INVALID),
  interested: Yup.array()
    .min(1, Constants.VALIDATIONS.INTERESTED_PRODUCTS_REQUIRED)
    .required(Constants.VALIDATIONS.INTERESTED_PRODUCTS_REQUIRED),
  description: Yup.string().trim(),
});

export {
  loginValidations,
  registerValidations,
  forgotPasswordValidations,
  changePasswordValidations,
  resetPasswordValidations,
  updateProfileValidations,
  manageAddressValidation,
  couponValidation,
  shippingValidation,
  contactValidation,
  cardValidation,
  quoteValidations,
};
