/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import CommonService from "../../API/Common";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import Loader from "../../Common/Loader";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { VALIDATIONS } from "../../Utils/constants";

const PrivacyPolicy: FC = () => {
  const [policy, setPolicy] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);

  const getPrivacyPolicy = async () => {
    setLoader(true);
    await CommonService.getPrivacyPolicy()
      .then((res: any) => {
        setLoader(false);
        if (res && res?.status === "Success") {
          let data = res?.data;
          let el = document.createElement("div");
          el.innerHTML = data;

          el.querySelectorAll("img").forEach((imgEl) => {
            const srcAtt = imgEl.getAttribute("src");
            if (srcAtt) {
              const srcOriginal = geturl(srcAtt);
              imgEl.src = srcOriginal;
            }
          });
          data = el.innerHTML;
          setPolicy(data);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function apiCall() {
      await getPrivacyPolicy();
    }
    apiCall();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Privacy & Policy",
      path: "#",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Privacy & Policy" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12">
            {policy ? (
              <span
                className="notranslate"
                dangerouslySetInnerHTML={{
                  __html: policy,
                }}
              ></span>
            ) : (
              <div className="mb-4 no-address--info text-center mt-3">
                <h4 className="no-address-txt">No Privacy & Policy</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
