import { FC } from "react";
import * as Constants from "../../Utils/constants";
import { NotificationWithIcon } from "../../Utils/helper";

interface Props {
  totalQuantity: number;
  quantity: number;
  updateQuantity: (value: number) => void;
}

const Quantity: FC<Props> = ({
  totalQuantity,
  quantity,
  updateQuantity,
}: Props) => {
  const decreaseQuantity = () => {
    if (quantity > 0) {
      updateQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    if (quantity === totalQuantity) {
      let err = Constants.VALIDATIONS.QUANTITY_LIMIT_ERROR;
      let quantityError = err.replaceAll(
        "{totalQuantity}",
        String(totalQuantity)
      );
      NotificationWithIcon("error", quantityError);
      return;
    }
    updateQuantity(quantity + 1);
  };

  return (
    <div className="input-group mb-0 product--detail__custom-input-group">
      <div className="input-group-prepend h-100">
        <button
          type="button"
          className="btn"
          onClick={() => decreaseQuantity()}
          disabled={quantity === 1}
        >
          -
        </button>
      </div>
      <input
        type="text"
        className="form-control h-100"
        aria-label="Text input with segmented dropdown button"
        value={quantity}
        readOnly
      />
      <div className="input-group-append h-100">
        <button
          type="button"
          className="btn"
          onClick={() => increaseQuantity()}
        >
          +
        </button>
      </div>
    </div>
  );
};

export default Quantity;
