import { AxiosResponse } from "axios";
import Service from "../../Lib/service";
import { getUserToken } from "../../Utils/helper";
const authorizationKey: any = process.env.REACT_APP_AUTHORIZATION_KEY;

const CartService = {
  createCart: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `carts`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.error("createCart error:",error);
      throw error;
    }
  },
  addUpdateCartItem: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `cart-items`, data },
        { authorization: `Bearer ${authorizationKey}` }
      );
    } catch (error) {
      console.log("addUpdateCartItem error:",error);
      throw error;
    }
  },
  getCart: async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`carts/${id}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getcart error:",error);
      throw error;
    }
  },
  deleteCartItem: async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.remove(`cart-items/${id}`, null, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("deleteCartItem error:",error);
      throw error;
    }
  },
  applyCoupon: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `cart-coupons`, data },
        {
          authorization: `Bearer ${authorizationKey}`,
        }
      );
    } catch (error) {
      console.log("applyCoupon error:",error);
      throw error;
    }
  },
  saveShippingDetails: async (
    data: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `cart-shippings`, data },
        {
          authorization: `Bearer ${authorizationKey}`,
        }
      );
    } catch (error) {
      console.log("saveShippingDetails error:",error);
      throw error;
    }
  },
  addCardDetails: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `my-cards`, data },
        {
          authorization: `Bearer ${getUserToken()}`,
        }
      );
    } catch (error) {
      console.log("addCardDetails error:",error);
      throw error;
    }
  },
  removeCard: async (id: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.remove(`my-cards/${id}`, null, {
        authorization: `Bearer ${getUserToken()}`,
      });
    } catch (error) {
      console.log("removeCard error:",error);
      throw error;
    }
  },
  getCards: async (id: number): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`my-cards/${id}`, {
        authorization: `Bearer ${getUserToken()}`,
      });
    } catch (error) {
      console.log("getCard error:",error);
      throw error;
    }
  },
  removeCoupon: async (id: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.remove(`cart-coupons/${id}`, null, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("removeCoupon error:",error);
      throw error;
    }
  },
  removeShipping: async (id: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.remove(`cart-shippings/${id}`, null, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("removeshipping error:",error);
      throw error;
    }
  },
  placeOrder: async (data: any): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `orders`, data },
        {
          authorization: `Bearer ${getUserToken()}`,
        }
      );
    } catch (error) {
      console.log("placeorder error:",error);
      throw error;
    }
  },
};

export default CartService;
