import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { useState, useEffect, FC } from "react";
import { useNavigate } from "react-router-dom";
import ProductService from "../../API/Product";
import Loader from "../../Common/Loader";
import { convertToUrlFormat, geturl, NotificationWithIcon } from "../../Utils/helper";
import { VALIDATIONS } from "../../Utils/constants";
import LoadImage from "../../Common/LoadImage";

const ServiceManuals: FC = () => {
  const [categories, setCategories] = useState<any>([]);
  const [haveData, setHaveData] = useState<boolean>(false);
  const [loader, setLoader] = useState<any>(false);
  const navigate = useNavigate();

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: "#",
    },
    {
      title: "Service and Operator’s Manuals Resources",
      path: "#",
    },
  ];

  const getCategoryList = async () => {
    setLoader(true);
    await ProductService.getServiceManualCategory()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          if (res?.data?.length > 0) {
            setHaveData(true);
            setCategories(res?.data);
          }
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getCategory() {
      await getCategoryList();
    }
    getCategory();
  }, []);

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Resources" />
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h4 className="block-title-xl">
              Service and Operator’s Manuals Resources
            </h4>
          </div>
        </div>
        <div className="row mt-3">
          {haveData &&
            categories?.length > 0 &&
            categories?.map(
              (item: {
                id: number;
                images: { url: string };
                title: string;
              }) => {
                return (
                  <div className="col-md-4 mb-4">
                    <div className="product-img-box">
                      <LoadImage
                        src={item?.images?.url}
                        inlineClass="img-fluid img-cursor"
                        onClick={() =>
                          navigate(`/manuals/${convertToUrlFormat(item?.title)}`, {
                            state: { id: item?.id },
                          })
                        }
                      />
                    </div>
                  </div>
                );
              }
            )}
          {!haveData &&
            categories?.length > 0 &&
            categories?.map((item: { images: any }) => {
              return (
                <div className="col-md-4 mb-4">
                  <div className="product-img-box">
                    <img
                      src={geturl(item?.images?.url)}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              );
            })}
          {categories && categories?.length === 0 && (
            <div>
              <h3>No data found</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ServiceManuals;
