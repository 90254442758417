/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loader from "../../Common/Loader";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import ROUTES from "../../routes";
import * as Constants from "../../Utils/constants";
import CommonService from "../../API/Common";
import Breadcrumb from "../../Common/Breadcrumb";

const News: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [newsData, setNewsData] = useState<any>(null);
  const [page] = useState<number>(1);
  const [isActive, setActive] = useState<any>({});

  const handleText = (id: number) => {
    setActive({ ...isActive, [id]: !isActive?.[id] });
  };

  const getNews = async () => {
    setLoader(true);
    const queryParams = `populate=*&pagination[page]=${page}&pagination[pageSize]=${Constants.PAGESIZE}`;
    await CommonService.getNews(queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          if (res?.data?.length > 0) {
            const newData = res?.data.map((i: { description: string }) => {
              let el = document.createElement("div");
              el.innerHTML = i.description;

              el.querySelectorAll("img").forEach((imgEl) => {
                const srcAtt = imgEl.getAttribute("src");
                if (srcAtt) {
                  const srcOriginal = geturl(srcAtt);
                  imgEl.src = srcOriginal;
                }
              });
              i.description = el.innerHTML;
              return i;
            });
            setNewsData(newData);
          }
          setLoader(false);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getNewsDetails() {
      await getNews();
    }
    getNewsDetails();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "News",
      path: "#",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="News" />
      {newsData && (
        <>
          <div className="container">
            <ul className="list-unstyled news--list">
              {newsData?.length > 0 &&
                newsData.map((item: { id: number; description: string }) => {
                  const desc = isActive?.[item.id]
                    ? item.description
                    : `${item.description.slice(
                        0,
                        Constants.READ_MORE_CHAR_SIZE
                      )}...`;
                  return (
                    <li className="news-list">
                      <span
                        className="notranslate"
                        dangerouslySetInnerHTML={{
                          __html: desc,
                        }}
                      ></span>
                      {item?.description?.length >
                        Constants.READ_MORE_CHAR_SIZE && (
                        <Link
                          to="#"
                          onClick={() => handleText(item.id)}
                          className="text--primary cmn-txt font-weight-medium"
                        >
                          {isActive?.[item.id] ? "Read Less" : "Read More"}
                        </Link>
                      )}
                    </li>
                  );
                })}
            </ul>
          </div>
        </>
      )}
    </>
  );
};

export default News;
