// #region Local Imports
import ActionTypes from "../../actionTypes";
// #endregion Local Imports

const INITIAL_STATE: any = {
  userInfo: null,
  isSocialLogin: false,
};

const AuthReducer = (state: any, action: any) => {
  if (!state) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case ActionTypes.Auth.SetReducer:
      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.Auth.ResetReducer:
      return INITIAL_STATE;

    case ActionTypes.Auth.SetUserInformation:
      return { ...state, userInfo: action.payload.userInfo };

    case ActionTypes.Auth.SetIsSocialLogin:
      return { ...state, isSocialLogin: action.payload.isSocialLogin };

    default:
      return state;
  }
};

export default AuthReducer;
