import { Link } from "react-router-dom";

const Breadcrumb = (props: any) => {
  const { breadcrumbs, pageTitle } = props;
  return (
    <>
      <div className="list-banner-block">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center">
          <h4 className="block-title-xxl text-white text-center">
            {pageTitle}
          </h4>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb mb-0">
              {breadcrumbs?.length > 0 &&
                breadcrumbs.map(
                  (item: { title: string; path: string }, index: number) => {
                    return (
                      <li className="breadcrumb-item" key={index}>
                        <Link
                          to={item.path}
                          className={
                            breadcrumbs.length === index + 1
                              ? "text-white"
                              : "text-muted"
                          }
                        >
                          {item?.title}
                        </Link>
                      </li>
                    );
                  }
                )}
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Breadcrumb;
