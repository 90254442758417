/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Form, Select } from "antd";
import { Field, Formik } from "formik";
import { FC, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AuthService from "../../API/Auth";
import Loader from "../../Common/Loader";
import SidebarLayout from "../../Common/SideBarLayout";
import AuthActions from "../../Redux/action/auth";
import * as Constants from "../../Utils/constants";
import {
  filterData,
  getCountries,
  getErrorMessage,
  NotificationWithIcon,
  validateContact,
} from "../../Utils/helper";
import { updateProfileValidations } from "../../Utils/validations";

const MyAccount: FC = () => {
  const [countries, setCountries] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);
  const authState = useSelector((state: any) => state.auth);
  const { userInfo } = authState;
  const dispatch = useDispatch();
  const defaultCountry: any = Constants.DEFAULT_COUNTRY;

  useEffect(() => {
    async function getCountry() {
      await getCountries(setCountries);
    }
    getCountry();
  }, []);

  const onUpdateProfile = async (values: {
    firstname: string;
    lastname: string;
    email: string;
    contact: string;
    country: number;
  }) => {
    setLoader(true);
    const data = {
      username: `${values.firstname.trim()} ${values.lastname.trim()}`,
      email: values.email,
      contactNumber: values.contact,
      country: Number(values.country),
    };
    await AuthService.updateProfile(userInfo?.id, data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", Constants.VALIDATIONS.PROFILE_UPDATE);
          dispatch(
            AuthActions.setUserInfo({
              userInfo: res?.data?.user,
            })
          );
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  return (
    <>
      {loader && <Loader />}
      <SidebarLayout activeTab="myAccount">
        <Row>
          <Col xs={24}>
            <h2 className="block-title block-title-xl-22 mb-3">My Account</h2>
            <div className="hr--divider mb-3"></div>
          </Col>
        </Row>
        <Container fluid className="px-0">
          <Formik
            initialValues={{
              firstname: userInfo?.username?.split(" ")?.[0] || "",
              lastname: userInfo?.username?.split(" ")?.[1] || "",
              email: userInfo?.email || "",
              contact: userInfo?.contactNumber || "",
              country: userInfo?.country?.id || defaultCountry?.id,
            }}
            onSubmit={(values: any) => {
              onUpdateProfile(values);
            }}
            validationSchema={updateProfileValidations}
            enableReinitialize={true}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form
                layout="vertical"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onSubmitCapture={handleSubmit}
              >
                <Row gutter={16}>
                  <Col className="col-lg-6 col-12">
                    <Form.Item
                      label="First name*"
                      className="custom-field mb-3"
                    >
                      <Field
                        placeholder="Enter firstname"
                        name="firstname"
                        type="text"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("firstname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12">
                    <Form.Item label="Last name*" className="custom-field mb-3">
                      <Field
                        name="lastname"
                        placeholder="Enter lastname"
                        type="text"
                        className="form-control custom-form-control"
                      />
                      {getErrorMessage("lastname")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12">
                    <Form.Item
                      label="Email address*"
                      className="custom-field mb-3"
                    >
                      <Field
                        name="email"
                        render={(fieldProps: any) => (
                          <input
                            {...fieldProps.field}
                            type="text"
                            placeholder="Enter email address"
                            className="form-control custom-form-control"
                            disabled
                          />
                        )}
                      />
                      {getErrorMessage("email")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12">
                    <Form.Item
                      label="Contact Number*"
                      className="custom-field mb-3"
                    >
                      <Field
                        id="contact"
                        name="contact"
                        validate={validateContact}
                        render={(renderProps: any) => (
                          <input
                            {...renderProps.field}
                            type="text"
                            placeholder="Enter contact number"
                            maxLength={Constants.CONTACT_LENGTH}
                            className="form-control custom-form-control"
                            disabled
                          />
                        )}
                      />
                      {getErrorMessage("contact")}
                    </Form.Item>
                  </Col>
                  <Col className="col-lg-6 col-12">
                    <Form.Item label="Country*" className="custom-field mb-3">
                      <Select
                        value={values.country}
                        placeholder="Select country"
                        popupClassName="custom--select"
                        showSearch
                        options={countries?.map((item: any) => ({
                          label: item?.name,
                          value: item?.id,
                        }))}
                        filterOption={(input: any, option: any) =>
                          filterData(input, option)
                        }
                        onChange={(e: any) => {
                          setFieldValue("country", e);
                        }}
                      />
                      {getErrorMessage("country")}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col className="col-lg-12 col-12 custom-btn">
                    <Form.Item>
                      <button
                        type="submit"
                        className="btn--primary btn--primary-md btn--primary--200 w-100 btn mt-2"
                      >
                        Update
                      </button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Container>
      </SidebarLayout>
    </>
  );
};

export default MyAccount;
