/* eslint-disable react-hooks/exhaustive-deps */
import { useState, FC } from "react";
import SidebarLayout from "../../Common/SideBarLayout";
import Loader from "../../Common/Loader";
import Cards from "../../Common/Cards";

const PaymentMethod: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  return (
    <>
      {loader && <Loader />}
      <SidebarLayout activeTab="payment">
        <Cards
          title="Payment Methods"
          setLoader={(value: boolean) => setLoader(value)}
          fromCart={false}
        />
      </SidebarLayout>
    </>
  );
};

export default PaymentMethod;
