/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import NotFound from "./Components/404NotFound";
import ArticleDetails from "./Components/ArticleDetails";
import Articles from "./Components/Articles";
import Cart from "./Components/Cart";
import CategoryList from "./Components/CategoryList";
import ContactUs from "./Components/ContactUs";
import FAQs from "./Components/FAQs";
import ReadOnlyCategory from "./Components/ReadOnlyCategory";
import Home from "./Components/Home";
import ReadOnlyProductDetails from "./Components/ReadOnlyProductDetails";
import News from "./Components/News";
import PartNumberRefs from "./Components/PartNumberRefs";
import ProductDetails from "./Components/ProductDetails";
import ProductList from "./Components/ProductList";
import ServiceManualDetail from "./Components/ServiceManualDetail";
import ServiceManuals from "./Components/ServiceManuals";
import ROUTES from "./routes";
import Tutorials from "./Components/Tutorials";
import RequestQuote from "./Components/RequestQuote";
import Layout from "./Components/Layout";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import Resource from "./Components/Resources";
import Aboutus from "./Components/AboutUs";

const MainRouter = (props: any) => {
  const { pathname, search } = useLocation();
  const [fromPage, setFromPage] = useState<string>("");
  const navigate = useNavigate();

  const checkRoute = () => {
    if (props.loginRoutes.includes(pathname)) {
      navigate(ROUTES.HOME);
    }
  };

  useEffect(() => {
    checkRoute();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const from: any = queryParams.get("fromPage");
    if (from) {
      setFromPage(from);
    } else {
      setFromPage("");
    }
  });

  return (
    <Layout fromPage={fromPage}>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route path={ROUTES.PRODUCTS} element={<ProductList />} />
        <Route path={ROUTES.PRODUCT_CATEGORY} element={<CategoryList />} />
        <Route path={ROUTES.PRODUCT_DETAILS} element={<ProductDetails />} />
        <Route path={ROUTES.MYCART} element={<Cart />} />
        <Route path={ROUTES.NEWS} element={<News />} />
        <Route path={ROUTES.CONTACT} element={<ContactUs />} />
        <Route path={ROUTES.SERVICE_MANUAL} element={<ServiceManuals />} />
        <Route path={ROUTES.MANUAL_DETAILS} element={<ServiceManualDetail />} />
        <Route path={ROUTES.FAQ} element={<FAQs />} />
        <Route path={ROUTES.ARTICLES} element={<Articles />} />
        <Route path={ROUTES.ARTICLESDETAILS} element={<ArticleDetails />} />
        <Route path={ROUTES.TUTORIALS} element={<Tutorials />} />
        <Route path={ROUTES.PARTNUMBER} element={<PartNumberRefs />} />
        <Route path={ROUTES.READONLYCATEGORY} element={<ReadOnlyCategory />} />
        <Route
          path={ROUTES.MAINPRODUCTDETAILS}
          element={<ReadOnlyProductDetails />}
        />
        <Route path={ROUTES.REQUEST_QUOTE} element={<RequestQuote />} />
        <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={ROUTES.RESOURCES} element={<Resource />} />
        <Route path={ROUTES.ABOUT_US} element={<Aboutus/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default MainRouter;
