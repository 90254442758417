import pdficon from "./../../assets/images/pdficon.svg";

const PdfListing = (props: any) => {
  const { pdfs } = props;
  return (
    <>
      {pdfs.map((dataItem: any) => {
        return (
          <li>
            <img src={pdficon} alt="" className="me-3" />
            <a href={dataItem?.pdf} target="_blank" rel="noreferrer">
              {dataItem?.pdfTitle}
            </a>
          </li>
        );
      })}
    </>
  );
};

export default PdfListing;
