/* eslint-disable react-hooks/exhaustive-deps */
import Breadcrumb from "../../Common/Breadcrumb";
import { FC, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ProductService from "../../API/Product";
import Loader from "../../Common/Loader";
import ROUTES from "../../routes";
import { geturl, NotificationWithIcon } from "../../Utils/helper";
import PdfListing from "../../Common/PdfListing";
import { VALIDATIONS } from "../../Utils/constants";
import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";

const ServiceManualDetail: FC = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [docs, setDocs] = useState<any>([]);
  const [search, setSearchValue] = useState<any>(null);
  const { id } = useLocation().state;
  const { title } = useParams();

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "Resources",
      path: ROUTES.SERVICE_MANUAL,
    },
    {
      title: "Service and Operator’s Manuals Resources Details",
      path: "#",
    },
  ];

  const getServiceManualDoc = async (searchValue?: string) => {
    setLoader(true);
    let url: string = "";
    if (searchValue) {
      url = `keyword=${searchValue}`;
    }
    await ProductService.getServiceManualDocs(id, url)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          const pdfArr = res?.data?.map(
            (data: { title: string; PDF: { url: string } }) => {
              return {
                pdfTitle: data?.title,
                pdf: geturl(data?.PDF?.url),
              };
            }
          );
          setDocs(pdfArr);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getServiceManuals() {
      await getServiceManualDoc();
    }
    getServiceManuals();
  }, [id]);

  const handleOnChange = async (e: any) => {
    setSearchValue(e.target.value);
    if (!e.target.value) {
      await getServiceManualDoc();
    }
  };

  const handleSearch = async (e: any) => {
    if (e.key === "Enter" && e.target.value.trim()) {
      await getServiceManualDoc(search);
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="Resources" />
      <div className="container pb-5">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <h4 className="block-title-xl">SERVICE MANUALS BY {title}</h4>
            <div className="custom-search ms-auto">
              <Input
                placeholder="Search by services"
                value={search}
                onChange={(e: any) => handleOnChange(e)}
                prefix={<SearchOutlined />}
                onKeyDown={(e: any) => handleSearch(e)}
              />
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12">
            <div className="list--box">
              <ul className="list-unstyled doc--list">
                {docs?.length > 0 && <PdfListing pdfs={docs} />}
              </ul>
            </div>
            {docs?.length === 0 && (
              <div>
                <h3>No data found</h3>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceManualDetail;
