const ActionTypes = {
  Auth: {
    ResetReducer: "Auth_ResetReducer",
    SetReducer: "Auth_SetReducer",
    SetUserInformation: "Auth_SetUserInformation",
    SetIsSocialLogin: "Auth_SetSocialLogin",
  },
  Cart: {
    ResetReducer: "Cart_ResetReducer",
    SetReducer: "Cart_SetReducer",
    setTotalItems: "Cart_SetTotalItems",
    setCartId: "Cart_SetCartId",
  },
};
export default ActionTypes;
