/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ErrorMessage, Field, Formik, Form } from "formik";
import AuthService from "../../API/Auth";
import Cookie from "js-cookie";
import { Form as form, Checkbox } from "antd";
import ROUTES from "../../routes";
import { loginValidations } from "../../Utils/validations";
import ReCAPTCHA from "react-google-recaptcha";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import AppleLogin from "react-apple-login";
import facebooklogo from "./../../assets/images/facebookicoon.svg";
import googlelogo from "./../../assets/images/googleicon.svg";
import applelogo from "./../../assets/images/appleicon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Constants from "../../Utils/constants";
import AuthActions from "../../Redux/action/auth";
import {
  decodedJwt,
  isUserLoggedIn,
  NotificationWithIcon,
} from "../../Utils/helper";
import Loader from "../../Common/Loader";
import CartActions from "../../Redux/action/cart";

const siteKey: any = process.env.REACT_APP_CAPTCHA_CLIENT_KEY;
const fbClientKey: any = process.env.REACT_APP_FACEBOOK_CLIENT_KEY;
const appleClientKey: any = process.env.REACT_APP_APPLE_CLIENT_KEY;
const appleRedirectUrl: any = process.env.REACT_APP_APPLE_REDIRECT_URL;

interface LoginModel {
  username: string | undefined;
  password?: string;
  recaptcha?: string;
}

interface Props {
  show: boolean;
  handleModal: any;
  handleRegisterData?: any;
}

const Login: FC<Props> = ({ show, handleModal, handleRegisterData }: Props) => {
  const [loader, setLoader] = useState<boolean>(false);
  const cartState = useSelector((state: any) => state.cart);
  const { cartId } = cartState;
  const recaptchaRef: any = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(useLocation().search);
  const fromPage = queryParams.get("fromPage");

  const handleRoute = () => {
    if (fromPage === "cart") {
      return ROUTES.MYCART;
    }
    return ROUTES.HOME;
  };

  const redirectTo = handleRoute();

  let formInitialValues: LoginModel = {
    username: "",
    password: "",
    recaptcha: "",
  };

  useEffect(() => {
    if (Cookie.get("username") && Cookie.get("password")) {
      formInitialValues.username = Cookie.get("username");
      formInitialValues.password = Cookie.get("password");
    }
  }, []);

  const handleClose = () => {
    handleModal("");
  };

  const onLogin = async (
    isSocialLogin: boolean,
    values: LoginModel,
    setFieldValue?: any
  ) => {
    setLoader(true);
    let data: any = {
      identifier: values.username,
    };
    if (!isSocialLogin) {
      data = { ...data, password: values.password };
    } else {
      data = { ...data, isSocialLogin };
    }
    if (!isUserLoggedIn() && cartId) {
      data = { ...data, cartId };
      dispatch(CartActions.Reset());
    }
    await AuthService.postLoginDetail(data)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          const { AUTH_TOKEN } = Constants;
          Cookie.set(AUTH_TOKEN, res?.data?.jwt);
          const { user } = res?.data;
          if (isSocialLogin) {
            dispatch(
              AuthActions.setIsSocialLogin({
                isSocialLogin: true,
              })
            );
          }
          dispatch(
            AuthActions.setUserInfo({
              userInfo: user,
            })
          );
          if (user?.cart) {
            dispatch(
              CartActions.setCartId({
                cartId: user?.cart?.id,
              })
            );
            dispatch(
              CartActions.setTotalItems({
                totalItems: user?.cart?.totalNoOfProducts,
              })
            );
          }
          handleClose();
          navigate(redirectTo);
          setTimeout(() => {
            navigate(0);
          }, 400);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        if (!isSocialLogin) {
          recaptchaRef.current.reset();
          setFieldValue("recaptcha", "");
        }
        NotificationWithIcon("error", err?.data?.error?.message);
      });
  };

  const checkUserExist = (email: string) => {
    return new Promise(async (resolve) => {
      setLoader(true);
      await AuthService.getUserByEmail({ data: { email } })
        .then((res: any) => {
          if (res && res?.status === "Success") {
            setLoader(false);
            resolve(res?.data?.user);
          }
        })
        .catch(() => {
          setLoader(false);
          resolve("");
        });
    });
  };

  const checkAfterUserExist = async (socialLoginRes: any) => {
    await checkUserExist(socialLoginRes.email).then((userExist: any) => {
      if (!userExist) {
        handleModal("register");
        handleRegisterData({
          socialLoginRes,
        });
      } else {
        onLogin(true, { username: socialLoginRes.email });
        updateProfile(userExist?.id, socialLoginRes);
      }
    });
  };

  const updateProfile = async (userId: number, req: any) => {
    setLoader(true);
    await AuthService.updateProfile(userId, req)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          NotificationWithIcon("success", Constants.VALIDATIONS.PROFILE_UPDATE);
          dispatch(
            AuthActions.setUserInfo({
              userInfo: res?.data?.user,
            })
          );
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon("error", err?.data?.error?.message);
      });
  };

  const responseGoogle = async (response: any) => {
    if (response?.error) {
      if (response?.error !== "popup_closed_by_user") {
        console.log("google error--", response?.error);
      }
    } else {
      if (response?.profileObj?.email) {
        const socialLoginRes: any = {
          firstname: response?.profileObj?.name?.split(" ")?.[0],
          lastname: response?.profileObj?.name?.split(" ")?.[1],
          email: response?.profileObj?.email,
          platform: Constants.SOCIAL_LOGIN_PROVIDER.GOOGLE,
        };
        await checkAfterUserExist(socialLoginRes);
      } else {
        NotificationWithIcon(
          "error",
          Constants.VALIDATIONS.SIGNIN_EMAIL_ACCESS_ERROR
        );
      }
    }
  };

  const onGoogleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        { headers: { Authorization: `Bearer ${tokenResponse?.access_token}` } }
      );
      responseGoogle({ profileObj: userInfo?.data });
    },
    onError: (errorResponse) => responseGoogle(errorResponse),
  });

  const responseFacebook = async (facebookRes: any) => {
    if (facebookRes?.email) {
      const socialLoginRes: any = {
        firstname: facebookRes?.name?.split(" ")?.[0],
        lastname: facebookRes?.name?.split(" ")?.[1],
        email: facebookRes?.email,
        platform: Constants.SOCIAL_LOGIN_PROVIDER.FACEBOOK,
      };
      await checkAfterUserExist(socialLoginRes);
    } else {
      NotificationWithIcon(
        "error",
        Constants.VALIDATIONS.SIGNIN_EMAIL_ACCESS_ERROR
      );
    }
  };

  const failResFacebook = (res: any) => {
    console.log("fb error--", res);
  };

  const checkRemember = (rememberValue: boolean, values: LoginModel) => {
    if (rememberValue) {
      Cookie.set("username", String(values.username));
      Cookie.set("password", String(values.password));
    }
  };

  const appleResponse = async (response: any) => {
    if (response?.error) {
      if (response?.error?.error !== "popup_closed_by_user") {
        console.log("apple error--", response?.error?.error);
      }
    } else {
      let socialLoginRes: any = {};
      if (response?.user) {
        socialLoginRes = {
          firstname: response?.user?.name?.firstName,
          lastname: response?.user?.name?.lastName,
          email: response?.user?.email,
          platform: Constants.SOCIAL_LOGIN_PROVIDER.APPLE,
        };
        await checkAfterUserExist(socialLoginRes);
      } else {
        const decode: any = decodedJwt(response?.authorization?.id_token);
        if (decode?.email) {
          socialLoginRes = {
            email: decode?.email,
            platform: Constants.SOCIAL_LOGIN_PROVIDER.APPLE,
          };
          await checkAfterUserExist(socialLoginRes);
        } else {
          NotificationWithIcon(
            "error",
            Constants.VALIDATIONS.SIGNIN_EMAIL_ACCESS_ERROR
          );
        }
      }
    }
  };

  return (
    <>
      {loader && <Loader />}
      <Modal
        show={show}
        onHide={handleClose}
        className="custom-modal custom-modal-sm custom-modal-login-form"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body className="login-form">
          <Formik
            initialValues={formInitialValues}
            onSubmit={(values, { setFieldValue }) => {
              onLogin(false, values, setFieldValue);
            }}
            validationSchema={loginValidations}
            validateOnBlur={false}
          >
            {({ values, errors, setFieldValue, setFieldTouched }) => (
              <Form>
                <form.Item
                  label="Username or email address*"
                  className="custom-field mb-3 title"
                >
                  <Field
                    id="username"
                    name="username"
                    placeholder="Enter username or email address"
                    className="form-control custom-form-control "
                  />
                  <ErrorMessage
                    name="username"
                    component="span"
                    className="error-msg"
                  />
                </form.Item>
                <form.Item label="Password*" className="custom-field mb-3">
                  <Field
                    id="password"
                    name="password"
                    placeholder="Enter password"
                    type="password"
                    className="form-control custom-form-control"
                  />{" "}
                  <ErrorMessage
                    name="password"
                    component="span"
                    className="error-msg"
                  />
                </form.Item>
                <form.Item className="mb-2">
                  <Checkbox onChange={(e: any) => checkRemember(e, values)}>
                    Remember me
                  </Checkbox>
                </form.Item>
                <form.Item >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={siteKey}
                    onChange={(response: any) => {
                      setFieldValue("recaptcha", response);
                      setFieldTouched("recaptcha", true);

                    }}
                    className="sign-in-capta"
                  />
                  <ErrorMessage
                    name="recaptcha"
                    component="span"
                    className="error-msg"
                  />
                </form.Item>
                <form.Item>
                  <button
                    type="submit"
                    className="w-100 btn--primary btn"
                    onClick={() => {
                      if (Object.keys(errors).length > 0) {
                        recaptchaRef.current.reset();
                        setFieldValue("recaptcha", "");
                      }
                    }}
                  >
                    Login
                  </button>
                </form.Item>
                <form.Item className="text-center mb-0">
                  <Link
                    to="#"
                    className="a-r-o-link"
                    onClick={() => handleModal("forgotPassword")}
                  >
                    Forgot your password?
                  </Link>
                </form.Item>
                <form.Item className="mb-0">
                  <div className="d-w-txt">
                    <span>Login with</span>
                  </div>
                </form.Item>
                <form.Item className="mb-0">
                  <ul className="d-flex list-unstyled justify-content-center">
                    <li>
                      <FacebookLogin
                        appId={fbClientKey}
                        fields="name,email,picture"
                        callback={responseFacebook}
                        onFailure={failResFacebook}
                        cssClass="my-facebook-button-class"
                        render={(renderProps) => (
                          <img
                            src={facebooklogo}
                            alt=""
                            onClick={renderProps.onClick}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      />
                    </li>
                    <li>
                      <img
                        src={googlelogo}
                        alt=""
                        onClick={() => onGoogleLogin()}
                        style={{ cursor: "pointer" }}
                      />
                    </li>
                    <li>
                      <AppleLogin
                        usePopup={true}
                        scope="name email"
                        responseMode="form_post"
                        clientId={appleClientKey}
                        redirectURI={appleRedirectUrl}
                        render={(renderProps) => (
                          <img
                            src={applelogo}
                            alt=""
                            onClick={renderProps.onClick}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                        callback={appleResponse} // Catch the response
                      />
                    </li>
                  </ul>
                </form.Item>
                <p className="text-center b-t-w-l">
                  Don’t have an account?
                  <Link
                    to="#"
                    className="ms-2 a-r-o-link"
                    onClick={() => handleModal("register")}
                  >
                    Register now
                  </Link>
                </p>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Login;
