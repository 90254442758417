import { Col, Container, Row } from "react-bootstrap";
import ROUTES from "../../routes";
import pagenotfound from "../../assets/images/pagenotfoundimg.png";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container className="container-small mt-4 mb-5">
      <Row>
        <Col className="col-12 text-center">
          <img src={pagenotfound} alt="" />
          <h2 className="block-title block-title-xl mt-3 mb-1 text--dark">
            Page Not Found
          </h2>
          <div className="error-block text-center">
            <p className="cmn-txt cmn-txt--18">
              The webpage you are looking for is not here!
            </p>
          </div>
          <div className="error-block text-center">
            <Link
              to={ROUTES.HOME}
              className="btn--primary__custom btn--primary btn mx-auto"
            >
              BACK TO HOME
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
