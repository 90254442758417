import logo from "../../assets/images/transparentlogo.png";
import facebooklogo from "../../assets/images/minifacebooklogo.svg";
import twitterlogo from "../../assets/images/minitwitterlogo.svg";
import instalogo from "../../assets/images/miniinstalogo.svg";
import linkedlogo from "../../assets/images/minilinkedinlogo.svg";
import { Link } from "react-router-dom";
import ROUTES from "../../routes";
import { useNavigate,useLocation } from 'react-router-dom';

const Footer = () => {

  const navigate = useNavigate();
  const location = useLocation();
  
  
  const scrollToProductSection = () => {
    if (location.pathname === '/') {
      const element = document.getElementById('product-section');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate('/', { state: { scrollToSection: 'product-section' } });
    }
  };

  return (
    <>
      <footer className="footer pb-0">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <img src={logo} alt="" />
              <p className="footer-company-desc mb-0">
                SkyAzúl Equipment Solutions is a proven leader in the sales and
                service of monitoring systems for construction and mining
                equipment.
              </p>
            </div>
            <div className="col-md-5">
              <label className="c__label">QUICK LINKS</label>
              <ul className="list-unstyled footer-link-list d-flex flex-wrap">
                <li>
                <Link
                      to="/"
                      onClick={scrollToProductSection}
                      >
                    Product
                </Link>
                </li>
                <li>
                  <Link to={ROUTES.ABOUT_US}>About Us</Link>
                </li>
                <li>
                  <Link to={ROUTES.REQUEST_QUOTE}>Request A Quote</Link>
                </li>
                <li>
                  <Link to={ROUTES.RESOURCES}>Resources</Link>
                </li>
                <li>
                  <Link to={ROUTES.PRODUCT_CATEGORY}>On-Line</Link>
                </li>
                <li>
                  <Link to={ROUTES.TUTORIALS}>Store Tutorials</Link>
                </li>
                <li>
                  <Link to={ROUTES.CONTACT}>Contact Us</Link>
                </li>
                <li>
                  <Link to={ROUTES.PRIVACY_POLICY}>Privacy & Policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3">
              <label className="c__label">CONTACT US</label>
              <address>
                16 Walnut Street,Middletown ,<br /> Maryland 21769 <br />
                (301) 371-6126 <br />
                info@skyazul.com
              </address>
            </div>
          </div>
        </div>
        <div className="sub-footer">
          <div className="container d-flex">
            <p className="footer-company-desc mb-0 mt-0">
              Copyright © 2024 Skyazul
            </p>
            <ul className="list-unstyled d-flex align-items-center ms-auto mb-0">
              <li>
                <a
                  href="https://www.facebook.com/SkyAzulEquipmentSolutions/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebooklogo} alt="" />
                </a>
              </li>
              <li className="ms-2">
                <img src={twitterlogo} alt="" />
              </li>
              <li className="ms-2">
                <img src={instalogo} alt="" />
              </li>
              <li className="ms-2">
                <img src={linkedlogo} alt="" />
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
