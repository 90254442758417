import { AxiosResponse } from "axios";
import Service from "../../Lib/service";

const authorizationKey: any = process.env.REACT_APP_AUTHORIZATION_KEY;

const ArticleService = {
    getArticlesList: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
        try {
          return await Service.get(`industry-standards?${url}`, {
            authorization: `Bearer ${authorizationKey}`,
          });
        } catch (error) {
          console.log("getArticlesList:",error);
          throw error;
        }
      },
    getArticleDetails: async (articleId: number,url: string): Promise<AxiosResponse<ApiDataType>> => {
        try {
        return await Service.get(`industry-standards/${articleId}?${url}`, {
         authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getArticleDetails:",error);
      throw error;
    }
  },
}

export default ArticleService;
