import { AxiosResponse } from "axios";
import Service from "../../Lib/service";
import { getUserToken } from "../../Utils/helper";

const authorizationKey: any = process.env.REACT_APP_AUTHORIZATION_KEY;

const CommonService = {
  getCountry: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`countries?all=true`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getCountry error:",error);
      throw error;
    }
  },
  getState: async (
    countryId: string,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`stateList/${countryId}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getState error:",error);
      throw error;
    }
  },
  getCity: async (
    stateId: string,
    url: string
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`cityList/${stateId}?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getCity error:",error);
      throw error;
    }
  },
  getHomeSliders: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`home-slider`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getHomeSliders error:",error);
      throw error;
    }
  },
  getNews: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`news?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getNews error:",error);
      throw error;
    }
  },
  getAboutus:  async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`about-us?${url}`,{}
    );
    } catch (error) {
      console.log("getAboutus error:",error);
      throw error;
    }
  },
  sendContactDetails: async (
    data: any
  ): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.post(
        { url: `contact-uses`, data },
        {
          authorization: `Bearer ${authorizationKey}`,
        }
      );
    } catch (error) {
      console.log("sendContactDetails error:",error);
      throw error;
    }
  },
  getFaqs: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`faqs?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getFaqs error:",error);
      throw error;
    }
  },
  getTutorials: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`tutorials?${url}`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getTutorials error:",error);
      throw error;
    }
  },
  getResources: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`resources?${url}`,{}
    );
    } catch (error) {
      console.log("getResources error:",error);
      throw error;
    }
  },
  getPartNumber: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`part-numbers?`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getPartNumber error:",error);
      throw error;
    }
  },
  getOrders: async (url: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`orders?${url}`, {
        authorization: `Bearer ${getUserToken()}`,
      });
    } catch (error) {
      console.log("getOrders error:",error);
      throw error;
    }
  },
  getPrivacyPolicy: async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
      return await Service.get(`privacy-policy`, {
        authorization: `Bearer ${authorizationKey}`,
      });
    } catch (error) {
      console.log("getPrivacyPolicy error:",error);
      throw error;
    }
  },
};

export default CommonService;
