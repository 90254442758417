// #region Global Imports
import { combineReducers } from "redux";
// #endregion Global Imports

// #region Local Imports
import AuthReducer from "./reducer/auth";
import CartReducer from "./reducer/cart";
// #endregion Local Imports

export default combineReducers({
  auth: AuthReducer,
  cart: CartReducer,
});
