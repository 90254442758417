import { useState } from "react";
import { geturl } from "../../Utils/helper";

interface ImageProps {
  src: string;
  inlineClass?: string;
  onClick?: () => void;
}

const LoadImage = ({ src, inlineClass, onClick }: ImageProps) => {
  const [defaultImage, setDefaultImage] = useState(false);
  return (
    <img
      src={
        defaultImage || !src
          ? "/assets/images/skyAzul_logo.jpg"
          : geturl(src) || "/assets/images/skyAzul_logo.jpg"
      }
      className={inlineClass}
      alt="product-pic"
      onClick={onClick}
      onError={() => setDefaultImage(true)}
    />
  );
};
export default LoadImage;
