// #region Local Imports
import ActionTypes from "../../actionTypes";
// #endregion Local Imports

const INITIAL_STATE: any = {
  totalItems: 0,
  cartId: null,
};

const CartReducer = (state: any, action: any) => {
  if (!state) {
    state = INITIAL_STATE;
  }
  switch (action.type) {
    case ActionTypes.Cart.SetReducer:
      return {
        ...state,
        ...action.payload,
      };

    case ActionTypes.Cart.ResetReducer:
      return INITIAL_STATE;

    case ActionTypes.Cart.setTotalItems:
      return { ...state, totalItems: action.payload.totalItems };

    case ActionTypes.Cart.setCartId:
      return { ...state, cartId: action.payload.cartId };

    default:
      return state;
  }
};

export default CartReducer;
