/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import ProductService from "../../API/Product";
import Loader from "../../Common/Loader";
import { getProductUrl, NotificationWithIcon, paginationTemplate } from "../../Utils/helper";
import * as Constants from "../../Utils/constants";
import Breadcrumb from "../../Common/Breadcrumb";
import ROUTES from "../../routes";
import { Select } from "antd";
import LoadImage from "../../Common/LoadImage";

const ProductList = () => {
  const [productList, setProductList] = useState<any>(null);
  const [page, setPage] = useState<number>(1);
  const [firstPage, setFirstPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [selectedSort, setSelectedSort] = useState<string>(
    Constants.PRODUCT_SORTING[0].value
  );
  const { id } = useParams();
  const navigate = useNavigate();

  const getProductList = async (
    categoryId: number,
    pageNumber: number,
    sorting: string
  ) => {
    setLoader(true);
    const queryParams = `start=${pageNumber}&limit=${Constants?.PAGESIZE}&sort=${sorting}&populate=*`;
    await ProductService.getProductList(categoryId, queryParams)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setProductList(res?.data);
          setTotalCount(res?.meta?.total);
        }
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        setProductList([]);
        NotificationWithIcon(
          "error",
          err?.data?.message || Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (id) {
      getProductList(Number(id), page, selectedSort);
    }
  }, []);

  const onPageChange = async (pageObj: any) => {
    setFirstPage(pageObj.first);
    setPage(pageObj.page + 1);
    await getProductList(Number(id), pageObj.page + 1, selectedSort);
  };

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "On-Line Store",
      path: ROUTES.PRODUCT_CATEGORY,
    },
  ];

  const handleSorting = async (value: string) => {
    setSelectedSort(value);
    setFirstPage(0);
    setPage(1);
    await getProductList(Number(id), 1, value);
  };

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="On-Line Store" />
      <div className="container">
        {productList && productList?.length > 0 && (
          <div className="row mb-3">
            <div className="col-md-6">
              <h4 className="block-title text--grey">
                Showing all {totalCount} results
              </h4>
            </div>
            <div className="col-md-6 d-flex">
              <Select
                className="ms-auto"
                popupClassName="custom--select"
                placeholder="--Select--"
                options={Constants.PRODUCT_SORTING}
                onChange={(e) => handleSorting(e)}
                value={selectedSort}
                style={{ width: "37%" }}
              />
            </div>
          </div>
        )}
        <div className="row">
          {productList &&
            productList?.length > 0 &&
            productList?.map((item: any) => {        
              return (
                <div className="col-md-3 mb-4" key={item?.id}>
                  <div className="card custom-card product-card border-0 product-hover-card">
                    <div className="card-body">
                      <LoadImage
                        src={item?.images?.[0]?.url}
                        inlineClass="img-fluid img-cursor"
                        onClick={() => navigate(`/product/${getProductUrl(item?.id, item?.title)}`)}
                      />
                    </div>
                  
                    <div className="card-footer p-0 pt-2 text-start">
                      
                      <Link
                        to={{
                          pathname: `/product/${getProductUrl(item?.id, item?.title)}`,
                        }}
                        className="product--title"
                      >
                        {item?.title}
                      </Link>
                      {item?.price > 0 && (
                        <p className="product--price">${item?.price}</p>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}


          
          {productList && productList?.length === 0 && (
            <div>
              <h3>No data found</h3>
            </div>
          )}
          <div className="col-12 mb-4">
            <div className="hr--divider border-light-grey"></div>
          </div>
          {productList && productList?.length > 0 && (
            <Paginator
              template={paginationTemplate}
              first={firstPage}
              rows={Constants.PAGESIZE}
              totalRecords={totalCount}
              onPageChange={onPageChange}
              className="custom--paginattion mb-5 justify-content-end"
            ></Paginator>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductList;
