/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ProductService from "../../API/Product";
import Breadcrumb from "../../Common/Breadcrumb";
import Loader from "../../Common/Loader";
import LoadImage from "../../Common/LoadImage";
import ROUTES from "../../routes";
import { VALIDATIONS } from "../../Utils/constants";
import { getCategoryUrl, NotificationWithIcon } from "../../Utils/helper";

const CategoryList = () => {
  const [categories, setCategories] = useState<any>([]);
  const [haveData, setHaveData] = useState<any>(false);
  const [loader, setLoader] = useState<any>(false);
  const navigate = useNavigate();

  const getCategoryList = async () => {
    setLoader(true);
    await ProductService.getCategoryList()
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setLoader(false);
          setCategories(res?.data);
          setHaveData(true);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message || VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getCategory() {
      await getCategoryList();
    }
    getCategory();
  }, []);

  const breadCrumbData = [
    {
      title: "Home",
      path: ROUTES.HOME,
    },
    {
      title: "On-Line Store",
      path: "#",
    },
  ];

  return (
    <>
      {loader && <Loader />}
      <Breadcrumb breadcrumbs={breadCrumbData} pageTitle="On-Line Store" />
      <div className="container">
        <div className="row">
          {haveData &&
            categories?.length > 0 &&
            categories?.map((item: any) => {
              return (
                <>
                {/* TODO */}
                  <div className="col-md-4 mb-4">
                    <div className="card custom-card product-card">
                      <div className="card-body">
                        <LoadImage
                          src={item?.images?.url}
                          inlineClass="img-fluid img-cursor"
                          onClick={() => navigate(`/product-category/${getCategoryUrl(item?.id, item?.title)}`)}
                        />
                      </div>
                      <div className="card-footer">
                        <Link
                          to={{
                            pathname: `/product-category/${getCategoryUrl(item?.id, item?.title)}`,
                          }}
                        >
                          {item?.title}
                        </Link>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          {haveData && categories?.length === 0 && (
            <div>
              <h3>No data found</h3>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryList;
