/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { Card, Collapse } from "react-bootstrap";
import SidebarLayout from "../../Common/SideBarLayout";
import { NotificationWithIcon, paginationTemplate } from "../../Utils/helper";
import CommonService from "../../API/Common";
import Loader from "../../Common/Loader";
import * as Constants from "../../Utils/constants";
import { Paginator } from "primereact/paginator";
import moment from "moment";
import LoadImage from "../../Common/LoadImage";

const MyOrders: FC = () => {
  const [showDetails, setShowDetail] = useState<any>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [orderData, setOrderData] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [firstPage, setFirstPage] = useState<number>(0);
  const [totalCount, setTotalCount] = useState<number>(0);

  const getOrders = async (pageNumber: number) => {
    setLoader(true);
    const url = `start=${pageNumber}&limit=${Constants.PAGESIZE}&sort=id:desc`;
    await CommonService.getOrders(url)
      .then((res: any) => {
        if (res && res?.status === "Success") {
          setOrderData(res?.data);
          setLoader(false);
          setTotalCount(res?.meta?.total);
        }
      })
      .catch((err: any) => {
        setLoader(false);
        NotificationWithIcon(
          "error",
          err?.data?.error?.message ||
            Constants.VALIDATIONS.SOMETHING_WENT_WRONG
        );
      });
  };

  useEffect(() => {
    async function getPartOrderDetails() {
      await getOrders(page);
    }
    getPartOrderDetails();
  }, []);

  const handleDisplayDetail = (id: any) => {
    setShowDetail({ ...showDetails, [id]: !showDetails?.[id] });
  };

  const onPageChange = async (pageObj: any) => {
    setFirstPage(pageObj.first);
    setPage(pageObj.page + 1);
    await getOrders(pageObj.page + 1);
  };

  const getClass = (status: string) => {
    switch (status) {
      case Constants.STATUS_DELIVERED:
        return "text--success";
      case Constants.STATUS_CANCELLED:
        return "text--warning";
      default:
        return "text--primary";
    }
  };

  return (
    <>
      {loader && <Loader />}
      <SidebarLayout activeTab="myOrders">
        <Row>
          <Col xs={24}>
            <h2 className="block-title block-title-xl-22 mb-3">My Orders</h2>
            <div className="hr--divider mb-3"></div>
          </Col>
        </Row>
        {orderData.map((dataItem: any) => (
          <Row>
            <Col xs={24} className="mb-3">
              <Card className="custom--card bg-grey collapse--card">
                <Card.Header className="d-flex align-items-center">
                  <h4 className="title me-auto">
                    <span className="text--grey">Order ID:</span> #
                    {dataItem?.id}
                  </h4>

                  <button
                    className="btn btn-borderd--primary"
                    onClick={() => handleDisplayDetail(dataItem?.id)}
                    aria-expanded={showDetails?.[dataItem?.id]}
                  >
                    {showDetails?.[dataItem?.id]
                      ? "Less Detail"
                      : "View Detail"}
                  </button>
                </Card.Header>
                <Collapse in={showDetails?.[dataItem?.id]}>
                  <Card.Body>
                    <ul className="list-unstyled d-flex justify-content-between mb-3 product--order-detail-list">
                      <li>
                        <span className="text--grey">Order Date:</span>{" "}
                        <span className="text--dark">
                          {dataItem?.createdAt
                            ? moment(dataItem?.createdAt).format(
                                Constants.PRODUCT_REVIEW_DATE_FROMAT
                              )
                            : "-"}
                        </span>
                      </li>
                      {!showDetails?.[dataItem?.id] && (
                        <li>
                          <span className="text--grey">Total (tax incl.):</span>
                          <span className="text--dark">
                            ${dataItem?.totalAmount} for{" "}
                            {dataItem?.order_items?.length} items
                          </span>
                        </li>
                      )}
                      <li className="text-end">
                        <span className="text--grey">Status:</span>
                        <span className={getClass(dataItem?.status)}>
                          {dataItem?.status}
                        </span>
                      </li>
                    </ul>
                    <ul className="list-unstyled d-flex justify-content-between mb-0 product--order-detail-list">
                      <li>
                        <span className="text--grey">Sub Total: </span>{" "}
                        <span className="text--dark">
                          ${dataItem?.subTotal}
                        </span>
                      </li>
                      <li>
                        <span className="text--grey">Shipping:</span>{" "}
                        <span className="text--dark">
                          ${dataItem?.shippingCharge}
                        </span>
                      </li>
                      <li className="text-end">
                        <span className="text--grey">Total:</span>{" "}
                        <span className="text--dark">
                          ${dataItem?.totalAmount}
                        </span>
                      </li>
                    </ul>
                    <div className="hr--divider my-3"></div>
                    <div className="row">
                      <div className="col-12">
                        <div className="product--detail__info">
                          <div className="table-row">
                            <div className="product--title font-weight-medium">
                              Billing Address:
                            </div>
                            <div className="info">
                              <p className="product--title font-weight-medium">
                                {dataItem?.billingStreetAddress},{" "}
                                {dataItem?.orderBillingCity?.name},{" "}
                                {dataItem?.orderBillingState?.name}-
                                {dataItem?.billingZipCode}
                              </p>
                              <p className="product--title font-weight-medium">
                                <span className="text--grey">Phone No.:</span>{" "}
                                {dataItem?.billingContactNumber}
                              </p>
                              <p className="product--title font-weight-medium">
                                <span className="text--grey">Email:</span>{" "}
                                {dataItem?.billingEmail}
                              </p>
                            </div>
                          </div>
                          <div className="table-row">
                            <div className="product--title font-weight-medium">
                              Shipping Address:
                            </div>
                            <div className="info">
                              <p className="product--title font-weight-medium">
                                {dataItem?.shippingStreetAddress},{" "}
                                {dataItem?.orderShippingCity?.name},{" "}
                                {dataItem?.orderShippingState?.name}-
                                {dataItem?.shippingZipCode}
                              </p>
                              <p className="product--title font-weight-medium">
                                <span className="text--grey">Phone No.:</span>{" "}
                                {dataItem?.shippingContactNumber}
                              </p>
                              <p className="product--title font-weight-medium">
                                <span className="text--grey">Email:</span>{" "}
                                {dataItem?.shippingEmail}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="hr--divider my-3"></div>
                    <div className="row">
                      <div className="col-12">
                        <h4 className="block-title font-weight-semibold">
                          Product Order:
                        </h4>

                        <div className="table-responsive">
                          <table className="table my--order-products-table mb-0">
                            {dataItem?.order_items?.map((orderItem: any) => (
                              <tr>
                                <td>
                                  <div className="d-flex my--order-product-details p-0">
                                    <LoadImage
                                      src={orderItem?.product?.images?.[0]?.url}
                                    />
                                    <h4>{orderItem?.product?.title}</h4>
                                  </div>
                                </td>
                                <td className="cmn-txt cmn-txt--18 font-weight-medium">
                                  Quantity:{" "}
                                  <span className="text--dark">
                                    {orderItem?.quantity}
                                  </span>
                                </td>
                                <td className="cmn-txt cmn-txt--18 font-weight-medium text-end text--dark">
                                  $
                                  {orderItem?.product?.price *
                                    orderItem.quantity}
                                </td>
                              </tr>
                            ))}
                          </table>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Collapse>
              </Card>
            </Col>
          </Row>
        ))}
        {orderData && orderData?.length > 0 && (
          <Paginator
            template={paginationTemplate}
            first={firstPage}
            rows={Constants.PAGESIZE}
            totalRecords={totalCount}
            onPageChange={() => onPageChange}
            className="custom--paginattion mb-5 justify-content-end"
          ></Paginator>
        )}
        {orderData?.length === 0 && (
          <>
            <div className="mb-4 no-address--info text-center mt-3">
              <h4 className="no-address-txt">No Orders Found</h4>
              <p className="mb-3">There are no orders placed yet</p>
            </div>
          </>
        )}
      </SidebarLayout>
    </>
  );
};

export default MyOrders;
